import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { IState } from '../types';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import UiHelper from '../helpers/UiHelper';
import { homeInitialState } from '../store/home';

function Help() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const control = home.control;
    const browserInfo = control.browserInfo;
    const pauseItEnabled = control.pauseItEnabled;
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'Help()', 'Mounted');
    }, [home]);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment translate={translate}>
            <div
                className={clsx(
                    UiHelper.GetClassNamesHeader(
                        control.isProd,
                        browserInfo.supported,
                        styleGeneral.body,
                        styleGeneral.bodyAlt1,
                        styleGeneral.bodyAlt2
                    ),
                    styleGuide.help
                )}
            >
                <div className={clsx(styleGuide.header)}>
                    <div className={clsx(styleGuide.heading)}>{translate('help.heading')}</div>
                </div>

                <div className={styleGuide.helpCard}>
                    <div className={styleGuide.whiteBackground}>
                        <div className={styleGuide.cardTitle}>{translate('help.customerCare')}</div>
                        {!pauseItEnabled && (
                            <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                                <a
                                    className={styleGuide.link}
                                    href={SystemHelper?.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_PHONE_NUMBER_LINK')}
                                    data-testid={'costumerCare-phone'}
                                >
                                    {SystemHelper?.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_PHONE_NUMBER_LABEL')}
                                </a>
                            </div>
                        )}
                        <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                            <a
                                className={styleGuide.link}
                                href={SystemHelper?.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_EMAIL_LINK')}
                                data-testid={'costumerCare-email'}
                            >
                                {SystemHelper?.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_EMAIL_LABEL')}
                            </a>
                        </div>
                        {!pauseItEnabled && (
                            <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                                <a
                                    className={styleGuide.link}
                                    target="__CUSTOMER__"
                                    href={SystemHelper?.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_URL_LINK')}
                                    data-testid={'costumerCare-website'}
                                >
                                    {SystemHelper?.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_URL_LABEL')}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                {!pauseItEnabled && (
                    <div className={styleGuide.helpCard}>
                        <div className={styleGuide.whiteBackground}>
                            <div className={styleGuide.cardTitle}>
                                {translate('help.podderCentral')}
                                <span className={styleGuide.labelAlt}>®</span>
                            </div>
                            <div className={styleGuide.podderText}>{translate('help.manageAccount')}</div>
                            <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                                <a
                                    className={styleGuide.link}
                                    target="__PODDER__"
                                    href={SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK')}
                                >
                                    {SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LABEL')}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styleGuide.helpCard}>
                    <div className={styleGuide.whiteBackground}>
                        <div className={styleGuide.cardTitle}>{translate('help.userGuide')}</div>
                        <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                            <a
                                className={styleGuide.link}
                                href={SystemHelper?.GetRuntimeConfig('REACT_APP_USER_GUIDE_URL')}
                                target="__GUIDE__"
                                download
                            >
                                {translate('help.omnipodDiscover')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default Help;
