import React from 'react';
import clsx from 'clsx';
import { ResponsiveBar } from '@nivo/bar';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IInsight3, ITranslator } from '../../../types';
import arrowByValueRange from '../../common/arrowByValueRange';
import classByValueRange from '../../common/classByValueRange';

function InsightSummaryInsulinUsage({ summary, translate }: { summary: IInsight3; translate: ITranslator }) {
    const getChartSettings = (data: any): any => {
        return {
            height: 20,
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            indexBy: 'index',
            padding: 0.15,
            enableLabel: false,
            innerPadding: 1,
            borderRadius: 8,
            labelTextColor: 'inherit:darker(1.4)',
            labelSkipWidth: 16,
            labelSkipHeight: 16,
            layout: 'horizontal',
            enableGridY: false,
            enableGridX: false,
            animate: true,
            areaOpacity: 0.15,
            axisBottom: null,
            axisLeft: null,
            axisRight: null,
            axisTop: null,
            colors: ['#c6c6c6', '#484848'],
            data,
            enablePointLabel: false,
            keys: ['Basal', 'Bolus'],
            xScale: null,
            yScale: null,
        };
    };

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={styleGuide.whiteBackground}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.insulinUsage}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{translate('insulinUsage')}</div>
                                        </div>
                                        <div className={styleGuide.subTitle}>{translate('averageUnitsPerDay')}</div>
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span
                                        className={clsx(
                                            styleGuide.rightTable,
                                            classByValueRange(summary.avgUnitsPerDayDelta)
                                        )}
                                    >
                                        <span className={styleGuide.icon}>
                                            <img
                                                src={arrowByValueRange(summary.avgUnitsPerDayDelta)}
                                                alt="arrow icon"
                                            />
                                        </span>
                                        <span className={styleGuide.text}>
                                            {Math.abs(summary.avgUnitsPerDayDelta)}%
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row2)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    {summary.avgUnitsPerDay}
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={clsx(styleGuide.middle)}>{translate('avgUnits')}</span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row3)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.chart)}>
                                    <ResponsiveBar
                                        {...getChartSettings([
                                            {
                                                Basal: summary.avgUnitsPerDayBasal,
                                                Bolus: summary.avgUnitsPerDayBolus,
                                                index: translate('avgUnits'),
                                            },
                                        ])}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row4)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGuide.caption)}>Basal</span>
                                    <span className={clsx(styleGuide.value, styleGuide.low)}>
                                        {summary.avgUnitsPerDayBasal} {translate('avgUnits')}
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={clsx(styleGuide.caption)}>Bolus</span>
                                    <span className={clsx(styleGuide.value, styleGuide.high)}>
                                        {summary.avgUnitsPerDayBolus} {translate('avgUnits')}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryInsulinUsage;
