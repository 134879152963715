import { Tooltip } from '@mui/material';
import { Toast } from 'react-bootstrap';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import React, { useEffect, useState } from 'react';

import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import { ICallback, IError, IHome, ITranslator } from '../../types';
import SystemHelper from '../../helpers/SystemHelper';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import UiHelper from '../../helpers/UiHelper';

function Notify({
    home,
    error,
    dismissToastCb,
    translate,
}: {
    home: IHome;
    error: IError;
    dismissToastCb: ICallback<void>;
    translate: ITranslator;
}) {
    const [title, setTitle] = useState('');
    const [show, setShow] = useState(false);
    const resetTitle = () => setTitle(translate('identification.clickToCopyToClipboard2'));
    const errorLevel = error?.level ?? ConstantsHelper.ErrorLevels.clear;
    const sticky = error?.sticky ?? false;
    let clsToastName = '';

    if (errorLevel > ConstantsHelper.ErrorLevels.success) {
        clsToastName = styleGeneral.messageBad;
    } else if (errorLevel < ConstantsHelper.ErrorLevels.success) {
        clsToastName = styleGeneral.messageInfo;
    } else {
        clsToastName = styleGeneral.messageGood;
    }

    SystemHelper.AppInsightsException(
        home,
        errorLevel > ConstantsHelper.ErrorLevels.success ? SeverityLevel.Error : SeverityLevel.Information,
        'Notify()',
        error?.title,
        error?.message
    );

    useEffect(() => {
        setShow(
            (errorLevel > ConstantsHelper.ErrorLevels.success && home?.control?.testMode) ||
                (errorLevel > ConstantsHelper.ErrorLevels.clear && ((error?.showAlways ?? false) || sticky))
        );
    }, [error?.showAlways, errorLevel, home?.control?.testMode, sticky]);

    useEffect(() => {
        // NOTE:  Use this to autohide the toast as using toast's "autohide={true}" will refresh the page as a side effect
        if (show && !sticky) {
            setTimeout(() => setShow(false), 5000);
        }
    }, [show, sticky]);

    return (
        <div className={styleGeneral.main}>
            <Draggable>
                <Toast className={styleGeneral.toast} onClose={dismissToastCb} show={show} autohide={false}>
                    <Toast.Header className={clsx(styleGuide.pageBanner, styleGeneral.banner)}>
                        <strong className={styleGeneral.title}>{error?.title}</strong>
                        <small className={styleGeneral.timestamp}>{error?.timeStamp}</small>
                    </Toast.Header>
                    <Toast.Body className={clsx(styleGeneral.message, clsToastName)}>
                        <Tooltip title={title}>
                            <span
                                className={clsx(styleGeneral.cell, styleGeneral.idValue, styleGeneral.info)}
                                onMouseEnter={() => resetTitle()}
                                onMouseLeave={() => resetTitle()}
                                onClick={() => {
                                    UiHelper.CopyToClipBoard(`${error?.message}`);
                                    setTitle(translate('identification.copiedToClipboard'));
                                }}
                                data-testid="testmessage"
                            >
                                {error?.message}
                            </span>
                        </Tooltip>
                    </Toast.Body>
                </Toast>
            </Draggable>
        </div>
    );
}

export default Notify;
