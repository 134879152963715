import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';

import SystemHelper from 'src/helpers/SystemHelper';
import { IState } from 'src/types';
import UiHelper from 'src/helpers/UiHelper';
import { homeInitialState } from 'src/store/home';

import LoginError from './LoginError';

import { sendAmplitudeEvent } from '../helpers/amplitude';
import ConstantsHelper from '../helpers/ConstantsHelper';
import styleGuide from '../styles/styleGuide.module.scss';

function LoginCallback() {
    const { oktaAuth, authState } = useOktaAuth();
    const [callbackError, setCallbackError] = useState(null);
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    useEffect(() => {
        oktaAuth
            .handleLoginRedirect()
            .then(() => {
                sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.SUCCESSFUL_LOGIN);
            })
            .catch((e) => {
                setCallbackError(e);
                sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.FAILED_LOGIN);
            });
    }, [oktaAuth]);

    useEffect(() => {
        if (authState?.error) {
            sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.FAILED_LOGIN);
        }
    }, [authState?.error]);

    const authError = authState?.error;
    const displayError = callbackError || authError;

    if (displayError) {
        return (
            <div className={styleGuide.loginCallback}>
                <LoginError error={displayError} translate={translate} />
            </div>
        );
    }

    return null;
}

export default LoginCallback;
