import { isEmpty } from 'lodash';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import styleGeneral from '../../styles/general.module.scss';
import { IAboutEntry, IBrowserInfo, IControl, IHome, IOktaData, IPatient, ITranslator } from '../../types';
import SystemHelper from '../../helpers/SystemHelper';
import UiHelper from '../../helpers/UiHelper';
import UtilityHelper from '../../helpers/UtilityHelper';

const getAboutEntries = (
    translate: ITranslator,
    home: IHome,
    patientOrig: IPatient,
    browserInfo: IBrowserInfo,
    oktaData: IOktaData
) => {
    const patient = {
        ...patientOrig,
        firstJoined: UtilityHelper.CryptoDecrypt(patientOrig.firstJoined) ?? '--',
        firstName: UtilityHelper.CryptoDecrypt(patientOrig.firstName) ?? '--',
        id: UtilityHelper.CryptoDecrypt(patientOrig.id) ?? '--',
        lastName: UtilityHelper.CryptoDecrypt(patientOrig.lastName) ?? '--',
        reportDaysAvailableString: `${UtilityHelper.ReportDaysAvailableGet(patientOrig, '--')}`,
    };
    const aboutEntries: IAboutEntry[] = [
        {
            isNonVerbose: true,
            nameLong: translate('identification.internalSourceId'),
            name: translate('identification.srcId'),
            value: home.idTag,
        },
        {
            nameLong: translate('identification.browserNameLong'),
            name: translate('identification.browserName'),
            value: browserInfo.name,
        },
        {
            nameLong: translate('identification.browserVersionLong'),
            name: translate('identification.browserVersion'),
            value: browserInfo.version,
        },
        {
            nameLong: translate('identification.browserOsLong'),
            name: translate('identification.browserOs'),
            value: browserInfo.os,
        },
        {
            isNonVerbose: true,
            nameLong: translate('identification.activeUserName'),
            name: translate('identification.userName'),
            value: oktaData.userName ?? '--',
        },
        {
            isNonVerbose: true,
            nameLong: translate('identification.pwdFirstName'),
            name: translate('identification.fName'),
            value: patient.firstName,
        },
        {
            isNonVerbose: true,
            nameLong: translate('identification.pwdLastName'),
            name: translate('identification.lName'),
            value: patient.lastName,
        },
        {
            nameLong: translate('identification.pwdFirstJoinedLong'),
            name: translate('identification.pwdFirstJoined'),
            value: patient.firstJoined,
        },
        {
            nameLong: translate('identification.reportDaysAvailableLong'),
            name: translate('identification.reportDaysAvailable'),
            value: patient.reportDaysAvailableString,
        },
        {
            nameLong: translate('identification.activeProfileLong'),
            name: translate('identification.activeProfile'),
            value: patient.id,
        },
        {
            isNonVerbose: true,
            nameLong: translate('identification.backendApiPwdUrl'),
            name: translate('identification.apiPwdUrl'),
            value: home.endpointPwdUrlPrefix ?? '--',
        },
        {
            isNonVerbose: true,
            nameLong: translate('identification.backendApiHcpUrl'),
            name: translate('identification.apiHcpUrl'),
            value: home.endpointHcpUrlPrefix ?? '--',
        },
        {
            isNonVerbose: true,
            nameLong: translate('identification.backendApiPdfUrl'),
            name: translate('identification.apiPdfUrl'),
            value: home.endpointPdfUrlPrefix ?? '--',
        },
        {
            nameLong: translate('identification.appInsightsKey'),
            name: translate('identification.aiKey'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_AZURE_APP_INSIGHTS_KEY', '--'),
        },
        {
            nameLong: translate('identification.amplitudeApiKey'),
            name: translate('identification.ampKey'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_AMPLITUDE_API_KEY', '--'),
        },
        {
            nameLong: translate('identification.repositoryBranch'),
            name: translate('identification.branch'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_TAG_BRANCH', '--'),
        },
        {
            nameLong: translate('identification.cicdBuildPipelineId'),
            name: translate('identification.cicdBuild'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_TAG_BUILD', '--'),
        },
        {
            nameLong: translate('identification.cicdReleasePipelineId'),
            name: translate('identification.cicdRelease'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_TAG_RELEASE', '--'),
        },
        {
            nameLong: translate('identification.versonBackend'),
            name: translate('identification.verBe'),
            value: home.versionBackEnd ?? '--',
        },
        {
            nameLong: translate('identification.versonFrontend'),
            name: translate('identification.verFe'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_TAG_VERSION', '--'),
        },
        {
            nameLong: translate('identification.browserSupportLong'),
            name: translate('identification.browserSupport'),
            value: SystemHelper.GetRuntimeConfig('REACT_APP_BROWSER_SUPPORT', '--'),
        },
        {
            nameLong: translate('identification.accessToken'),
            name: translate('identification.aToken'),
            value: oktaData.access,
        },
    ];

    return aboutEntries;
};

const renderEntry = (
    translate: ITranslator,
    title: string,
    aboutEntry: IAboutEntry,
    idx: number,
    verbose: boolean,
    setTitle: any,
    resetTitle: any
) => (
    <div key={`idRow${idx}`} className={clsx(styleGeneral.row, verbose ? styleGeneral.verbose : styleGeneral.brief)}>
        <span className={clsx(styleGeneral.cell, styleGeneral.idName)}>
            {verbose ? aboutEntry.nameLong : aboutEntry.name}:
        </span>
        <Tooltip title={title}>
            <span
                className={clsx(styleGeneral.cell, styleGeneral.idValue, styleGeneral.info)}
                onMouseEnter={() => resetTitle()}
                onMouseLeave={() => resetTitle()}
                onClick={() => {
                    UiHelper.CopyToClipBoard(`${aboutEntry.value}`);
                    setTitle(translate('identification.copiedToClipboard'));
                }}
            >
                {aboutEntry.value}
            </span>
        </Tooltip>
    </div>
);

function Identificiation({ home, translate }: { home: IHome; translate: ITranslator }) {
    const [title, setTitle] = useState('');

    const control = home.control ?? ({} as IControl);
    const browserInfo = control.browserInfo ?? ({} as IBrowserInfo);
    const oktaData = home.authentication?.oktaData ?? ({} as IOktaData);
    const patient = home.patient ?? ({} as IPatient);
    const verbose = control.testMode && control.testTips;

    if (isEmpty(control) || isEmpty(oktaData)) {
        return null;
    }

    const resetTitle = () => setTitle(translate('identification.clickToCopyToClipboard'));
    const aboutEntries = getAboutEntries(translate, home, patient, browserInfo, oktaData);

    return !(control.testTips && control.testMode) ? null : (
        <div className={styleGeneral.main} data-testid="identification">
            <div className={clsx(styleGeneral.identification, styleGeneral.testModeOn)}>
                {verbose && <div className={styleGeneral.idBanner}>{translate('identification.title')}</div>}
                <div className={styleGeneral.table}>
                    <div className={styleGeneral.testModeOn}>
                        {aboutEntries
                            .filter((aboutEntry) => verbose || (!verbose && aboutEntry.isNonVerbose))
                            .map((aboutEntry, idx) =>
                                renderEntry(translate, title, aboutEntry, idx, verbose, setTitle, resetTitle)
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Identificiation;
