import ConstantsHelper from './ConstantsHelper';
import NavigationHelper from './NavigationHelper';

import { IClickCoordinates, IPdfPageConfigType, IRectangleSize } from '../types';

export default class ScreenHelper {
    public static GetPdfConfig = (divWidth: number) => {
        enum sizeEnum {
            small = 1,
            medium = 2,
            large = 3,
        }
        const flagWidthSize = divWidth < 800 ? sizeEnum.medium : sizeEnum.large;
        const typeCurr: sizeEnum = divWidth < 400 ? sizeEnum.small : flagWidthSize;
        const pageConfigs: IPdfPageConfigType[] = [
            { type: sizeEnum.small, left: 1.75, top: 1.0, width: 5.08, height: 11.0 },
            { type: sizeEnum.medium, left: 0.5, top: 0.5, width: 7.5, height: 11.0 },
            { type: sizeEnum.large, left: 0.25, top: 0.25, width: 7.9, height: 11.0 },
        ];

        return {
            pageConfigs,
            typeCurr,
        };
    };

    public static GetControlPanelPos = (
        windowSize: IRectangleSize,
        panelPosOld: IClickCoordinates
    ): IClickCoordinates => {
        const panelPos: IClickCoordinates = { left: panelPosOld?.left ?? 0, top: panelPosOld?.top ?? 0 };
        const panelDims: IRectangleSize = NavigationHelper.GetElementDimensions(ConstantsHelper.ControlPanelId);
        let positionChanged = false;

        if (panelPos.left + panelDims.width > windowSize.width) {
            panelPos.left = windowSize.width - panelDims.width;
            positionChanged = true;
        }

        if (panelPos.top + panelDims.height > windowSize.height) {
            panelPos.top = windowSize.height - panelDims.height;
            positionChanged = true;
        }

        if (positionChanged) {
            panelPos.left = Math.max(2, panelPos.left);
            panelPos.top = Math.max(2, panelPos.top);
        }

        return panelPos;
    };
}
