import React from 'react';
import clsx from 'clsx';

import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import welcomeImage from '../../../assets/images/welcome-image.png';
import { IBrowserInfo, ICallback, IControl, IHome, ITranslator } from '../../../types';
import UiHelper from '../../../helpers/UiHelper';

function ContentWrapperWelcome({
    home,
    closeCb,
    translate,
}: {
    home: IHome;
    closeCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const browserInfo = control.browserInfo ?? ({} as IBrowserInfo);

    return (
        <>
            <div
                className={clsx(
                    styleGeneral.layerTopmostLess1,
                    UiHelper.GetClassNamesHeader(
                        control.isProd,
                        browserInfo.supported,
                        styleGuide.modalBackdrop,
                        styleGuide.modalBackdropAlt1,
                        styleGuide.modalBackdropAlt2
                    )
                )}
            ></div>
            <div className={clsx(styleGeneral.layerTopmostLess1, styleGuide.welcome)}>
                <img src={welcomeImage} className={clsx(styleGuide.icon)} alt={translate('welcome.icon')} />
                <div className={styleGuide.title}>{translate('welcome.title')}</div>
                <div className={styleGuide.body}>
                    {translate('welcome.body', {
                        name: translate(ConstantsHelper.NameTag),
                    })}
                </div>
                <BtnPrimary caption={translate('welcome.continue')} cbClickFn={() => closeCb()} focus={false} />
            </div>
        </>
    );
}

export default ContentWrapperWelcome;
