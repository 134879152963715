import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import BtnBack from '../components/common/btnBack';
import SystemHelper from '../helpers/SystemHelper';
import {
    anchorInsightActionCreator,
    controlActionCreator,
    carouselSetIndexActionCreator,
    dashboardMetaDataActionCreator,
    insightIdActionCreator,
    intersessionDataSaveActionCreator,
    homeInitialState,
    anchorLoyaltyCongratsActionCreator,
    anchorLoyaltyInsulinActionCreator,
} from '../store/home';
import { IBrowserInfo, IControl, IDashboardCache, IHome, IState, ITimestampRange, ITranslator } from '../types';
import DateTimeHelper from '../helpers/DateTimeHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import InsightsFrame from '../components/dashboard/insightsFrame';
import UiHelper from '../helpers/UiHelper';
import WeekPicker from '../components/dashboard/weekPicker';
import ConstantsHelper from '../helpers/ConstantsHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import { sendAmplitudeEvent } from '../helpers/amplitude';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import { Insight3, Insight3GlucoseTrend } from '../model/models';

const getInsightInDateRange = (
    dispatch: any,
    history: any,
    homeHandle: IHome,
    dashboardEnd: string,
    beg: string,
    end: string,
    dashboardHasReferrerInsight: boolean
) => {
    if (UtilityHelper.IsNull(dashboardEnd)) {
        history.push(NavigationHelper.GenUrlDashboard(beg, end, dashboardHasReferrerInsight));
    } else if (
        !(homeHandle.dashboardBeg === beg && homeHandle.dashboardEnd === end) ||
        dashboardHasReferrerInsight ||
        (homeHandle.dashboardBeg === beg && homeHandle.dashboardEnd === end && !homeHandle.currentDashboard?.insightIds)
    ) {
        const payload = UiHelper.GetInsightsDateRange(dispatch, homeHandle, beg, end, dashboardHasReferrerInsight);

        if (payload.beg && payload.end) {
            UiHelper.ClearErrors(dispatch);
            UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, homeHandle);
            UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, homeHandle);
            UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, homeHandle);
            UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, homeHandle);
            UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, homeHandle);
        }
    }
};
const armInsightEvents = (dispatch: any, { insightId, anchorInsight, patternType, insightType }: any): void => {
    sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.CLICK_INSIGHT_DETAILS, {
        insightId,
        insightType,
        patternType,
    });
    dispatch(insightIdActionCreator({ insightId }));
    dispatch(controlActionCreator({ control: { message: undefined } }));
    dispatch(intersessionDataSaveActionCreator({ keys: [ConstantsHelper.IntersessionKeys.insightId] }));
    dispatch(anchorInsightActionCreator({ anchorInsight }));
};
const cbCarouselSetIndexFn = (dispatch: any, home: IHome, { index }: { index: number }) => {
    // Note:
    //     There seems to be bug in the carousel where double-clicking on the selector arrows will
    //     keep firing index changed event where both old and new indices are equal.
    //     This results in an infinite loop that is broken with this conditional check
    if (home.carouselIndex !== index) {
        dispatch(carouselSetIndexActionCreator({ index }));
    }
};
const handleClick = (dispatch: any, homeHandle: IHome, dashboardEnd: string, newDateRange: ITimestampRange) => {
    if (newDateRange) {
        getInsightInDateRange(dispatch, history, homeHandle, dashboardEnd, newDateRange.beg, newDateRange.end, false);

        window.history.replaceState(null, '', `/week/${newDateRange.beg}/${newDateRange.end}/false`);
    }
};
const clearAnchorInsight = (dispatch: any) => dispatch(anchorInsightActionCreator({ anchorInsight: undefined }));
const setAnchorLoyaltyCongrats = (dispatch: any, anchorLoyaltyCongrats?: string) =>
    dispatch(anchorLoyaltyCongratsActionCreator({ anchorLoyaltyCongrats }));
const setAnchorLoyaltyInsulin = (dispatch: any, anchorLoyaltyInsulin?: string) =>
    dispatch(anchorLoyaltyInsulinActionCreator({ anchorLoyaltyInsulin }));
const renderInsights = ({
    dispatch,
    home,
    translate,
    control,
    browserInfo,
    handleScroll,
    scrollActive1,
    scrollActive2,
    pwdName,
    dashboardEnd,
    dashboard,
    summary,
    summaryGlucoseTrend,
}: {
    dispatch: any;
    home: IHome;
    translate: ITranslator;
    control: IControl;
    browserInfo: IBrowserInfo;
    handleScroll: any;
    scrollActive1: boolean;
    scrollActive2: boolean;
    pwdName: string;
    dashboardEnd: string;
    dashboard: IDashboardCache;
    summary: Insight3;
    summaryGlucoseTrend: Insight3GlucoseTrend;
}) => (
    <div
        className={UiHelper.GetClassNamesHeader(
            control.isProd,
            browserInfo.supported,
            styleGeneral.body,
            styleGeneral.bodyAlt1,
            styleGeneral.bodyAlt2
        )}
        onScroll={(event) => handleScroll(event)}
    >
        {home.dashboardHasReferrer && (
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack url="/all-reports" />
            </div>
        )}
        <div
            className={clsx(
                styleGuide.report,
                styleGuide.solidBgWhite,
                home.dashboardHasReferrer ? styleGeneral.stickToTopLevel2 : styleGeneral.stickToTopLevel1
            )}
            data-testid="insights"
        >
            <div
                className={clsx(
                    styleGuide.header,
                    styleGuide.solidBgWhite,
                    styleGeneral.dashboardZoomInScroll1OffScroll2Off
                )}
            >
                <div
                    className={clsx(
                        home.dashboardHasReferrer ? styleGuide.patientNameAlt : styleGuide.patientName,
                        scrollActive1 && styleGeneral.inScroll2
                    )}
                >
                    {pwdName.length > 0 ? pwdName : '--'}
                </div>
                <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                    <div className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}>
                        {DateTimeHelper.FormatDateRange(home.dashboardEnd)}
                    </div>
                    {control.testMode && control.testCalendar && !home.dashboardHasReferrer && (
                        <div className={clsx(styleGeneral.rangeSelectorFrame, styleGeneral.layerTopmostLess2)}>
                            <WeekPicker
                                home={home}
                                cbClickFn={(newDateRange: ITimestampRange) =>
                                    handleClick(dispatch, home, dashboardEnd, newDateRange)
                                }
                                translate={translate}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
        <InsightsFrame
            home={home}
            translate={translate}
            scrollActive1={scrollActive1}
            scrollActive2={scrollActive2}
            dashboard={dashboard}
            summary={summary}
            summaryGlucoseTrend={summaryGlucoseTrend}
            cbClickFn={(clickParams: any) => armInsightEvents(dispatch, clickParams)}
            cbCarouselSetIndexFn={(setIndexParams: any) => cbCarouselSetIndexFn(dispatch, home, setIndexParams)}
            cbLoyaltyCongrats={(anchor: string) => setAnchorLoyaltyCongrats(dispatch, anchor)}
            cbLoyaltyInsulin={(anchor: string) => setAnchorLoyaltyInsulin(dispatch, anchor)}
        />
    </div>
);
function Dashboard(props: any) {
    const history = props?.history;
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const oktaData = home.authentication.oktaData;
    const control = home.control;
    const browserInfo = control.browserInfo;
    const patient = home.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer } = props?.match?.params ?? {};

    useEffect(() => {
        if (
            home.allLoaded &&
            !(
                home.dashboardBeg === dashboardBeg &&
                home.dashboardEnd === dashboardEnd &&
                home.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg: dashboardBeg,
                    dashboardEnd: dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.allLoaded, dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    const dashboard = home.currentDashboard;
    const summary = home.currentSummary;
    const summaryGlucoseTrend = home.currentSummaryGlucoseTrend;
    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            12,
            scrollActive1,
            scrollActive2,
            setScrollActive1,
            setScrollActive2,
            event?.target?.scrollTop
        );

    useLayoutEffect(() => {
        if (oktaData.userName) {
            SystemHelper.AppInsightsEvent(home, 'Dashboard()', 'Mounted');
            sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.VIEW_DASHBOARD, {
                userName: `${home.authentication.oktaData.userName}`,
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (patient.receivedData && !dashboard?.insightIds && home.allLoaded) {
            const hasReferrer = UtilityHelper.IsNull(dashboardHasReferrer)
                ? home.dashboardHasReferrer
                : dashboardHasReferrer === 'true';
            const dashboardEndFallback: string = home.dashboardEnd;
            const dashboardEnd1: string =
                home.dashboardEnd ??
                dashboardEndFallback ??
                DateTimeHelper.GetDateNearestPastSaturday(dashboardEndFallback, 6);
            let dateRangeCurr = DateTimeHelper.GetIsoWeekRange(dashboardEnd1, 0);

            if (!dateRangeCurr.end) {
                dateRangeCurr = DateTimeHelper.GetIsoWeekRange(
                    DateTimeHelper.GetDateNearestPastSaturday(dashboardEndFallback, 0),
                    0
                );
            }
            getInsightInDateRange(
                dispatch,
                history,
                home,
                dashboardEnd,
                dateRangeCurr.beg,
                dateRangeCurr.end,
                hasReferrer
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        patient.receivedData,
        home.dashboardEnd,
        home.allLoaded,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    useEffect(() => {
        if (home.anchorInsight && dashboard?.insightIds) {
            NavigationHelper.ScrollIntoView(home.anchorInsight, () => clearAnchorInsight(dispatch));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.anchorInsight, dashboard?.insightIds]);

    useEffect(() => {
        if (home.anchorLoyaltyCongrats && dashboard?.insightIds) {
            NavigationHelper.ScrollIntoView(home.anchorLoyaltyCongrats, () => setAnchorLoyaltyCongrats(dispatch));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.anchorLoyaltyCongrats, dashboard?.insightIds]);

    useEffect(() => {
        if (home.anchorLoyaltyInsulin && dashboard?.insightIds) {
            NavigationHelper.ScrollIntoView(home.anchorLoyaltyInsulin, () => setAnchorLoyaltyInsulin(dispatch));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.anchorLoyaltyInsulin, dashboard?.insightIds]);

    useEffect(() => {
        if (UtilityHelper.IsNull(home.idTag) && patient.receivedData) {
            // Pull this after profile is fetched in order to not overwrite the error object which may be set to error by fetch profile
            UiHelper.FetchBackEndId(dispatch, home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        home.idTag,
        patient.receivedData,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    // TODO:  Add tests for this effect under tech debt work
    useEffect(() => {
        if (
            !UtilityHelper.IsNull(home.dashboardBeg) &&
            !UtilityHelper.IsNull(home.dashboardEnd) &&
            (home.dashboardEnd !== home.currentLearningMaterialProgress.id ||
                DateTimeHelper.GetIsoNow() > home.currentLearningMaterialProgress.expires)
        ) {
            UiHelper.FetchLearningMaterialOp5Progress(dispatch, home.dashboardBeg, home.dashboardEnd, home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        home.dashboardBeg,
        home.dashboardEnd,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    const pwdName = useMemo(
        () => UtilityHelper.GetFullName(home.patient?.firstName, home.patient?.lastName),
        [home.patient?.firstName, home.patient?.lastName]
    );

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment={false} translate={translate}>
            {dashboard?.insightIds &&
                renderInsights({
                    dispatch,
                    home,
                    translate,
                    control,
                    browserInfo,
                    handleScroll,
                    scrollActive1,
                    scrollActive2,
                    pwdName,
                    dashboardEnd,
                    dashboard,
                    summary,
                    summaryGlucoseTrend,
                })}
        </ContentWrapperAll>
    );
}

export default Dashboard;
