import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IBrowserInfo, ICallback, IControl, IHome, ITranslator } from '../../../types';
import UiHelper from '../../../helpers/UiHelper';
import BtnPrimary from '../btnPrimary';

function ContentWrapperEnrollmentRevoked({
    home,
    podderCentralCb,
    translate,
}: {
    home: IHome;
    podderCentralCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const browserInfo = control.browserInfo ?? ({} as IBrowserInfo);

    return (
        <div
            className={UiHelper.GetClassNamesHeader(
                control.isProd,
                browserInfo.supported,
                styleGuide.body,
                styleGuide.bodyAlt1,
                styleGuide.bodyAlt2
            )}
        >
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.enrollmentRevoked
                )}
            >
                <div className={styleGuide.title}>{translate('enrollmentRevoked.title')}</div>
                <div className={styleGuide.body}>{translate('enrollmentRevoked.subTitle')}</div>
                <div>
                    <BtnPrimary caption={translate('enrollmentRevoked.enrollNow')} cbClickFn={podderCentralCb} />
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEnrollmentRevoked;
