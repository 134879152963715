import ConstantsHelper from '../../helpers/ConstantsHelper';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import { IHome } from '../../types';

export const homeInitialState: IHome = {
    allLoaded: false,
    allReportsRowCnt: 0,
    anI18Nextlib: AnI18NextLibHelper.loadLang('en'),
    anchorInsight: undefined,
    anchorEvent: undefined,
    anchorAllReports: undefined,
    anchorLoyaltyCongrats: undefined,
    anchorLoyaltyInsulin: undefined,
    authentication: {
        oktaData: {
            access: undefined,
            aud: undefined,
            isUserDefined: undefined,
            expires: undefined,
            id: undefined,
            userName: undefined,
        },
    },
    cachedInsights: {},
    control: {
        browserInfo: {
            name: '',
            os: '',
            supported: true,
            tag: 'unknown',
            version: '',
        },
        activeHttpCalls: {},
        isProd: true,
        message: undefined,
        panelExpanded: true,
        panelPos: {
            left: 2,
            top: 2,
        },
        showLegend: true,
        readingsInfo: {
            fillGaps: false,
            flipDateBy90: true,
            lowerBolus: false,
            showBoundaries: false,
            showRecsBolus: true,
            showRecsEgv: true,
            showRecsMode: true,
            showRecsPod: true,
            showRecsSubMode: true,
            showTable: false,
        },
        testCalendar: true,
        testForcePending: false,
        testForceRevoked: false,
        testModeAllowed: false,
        testMode: false,
        testFuture: false,
        testGenData: false,
        testPauseItEnabledOverride: false,
        testKeepOffboard: false,
        testKeepOnboard: false,
        testTips: false,
        windowSize: {
            height: 0,
            width: 0,
        },
        pauseItEnabled: undefined,
    },
    currentDashboard: {},
    currentReadingCombo: {},
    currentLearningMaterialProgress: { id: '', data: {} },
    currentLearningMaterialResource: { id: '', data: {} },
    currentInsulinUsage: {},
    currentSummary: {},
    currentSummaryGlucoseTrend: {},
    calendarDate: undefined,
    carouselIndex: 0,
    contributingEventId: undefined,
    contributingEventBeg: undefined,
    contributingEventEnd: undefined,
    dashboardBeg: undefined,
    dashboardEnd: undefined,
    dashboardHasReferrer: false,
    endpointPwdUrlPrefix: undefined,
    endpointHcpUrlPrefix: undefined,
    endpointPdfUrlPrefix: undefined,
    errors: {
        general: {
            level: ConstantsHelper.ErrorLevels.clear,
            message: '',
            title: '',
        },
    },
    idTag: '20230507',
    insightId: undefined,
    isMenuOpen: false,
    loadingSemaphore: 0,
    patient: {
        reportDaysAvailableString: undefined,
        isEnrolled: false,
        isUnenrolled: false,
        isOnboarded: false,
        receivedData: false,
        eulaAccepted: false,
        confidentialityAgreementAccepted: false,
    },
    versionBackEnd: undefined,
    isPolling: false,
};
