import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Switch, useHistory, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import clsx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';

import TelemetryProvider from './appInsightsTelemetryProvider';

import Header from '../components/app/header';
import AnI18NextLibHelper from '../helpers/AnI18NextLibHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import MenuHelper from '../helpers/MenuHelper';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import { IControl, IHome, IMenuEntry, IPatient, IState } from '../types';
import {
    dashboardMetaDataActionCreator,
    endpointUrlPrefixActionCreator,
    intersessionDataRestoreActionCreator,
    isMenuOpenActionCreator,
    anI18NextLibActionCreator,
    anchorAllReportsActionCreator,
    updateProfile,
    fetchProfile,
    updatePatientActionCreator,
    homeInitialState,
} from '../store/home';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import 'react-datepicker/dist/react-datepicker.css';
import './App.scss';
import Notify from '../components/app/notify';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import * as pages from '../pages';
import DateTimeHelper from '../helpers/DateTimeHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import ControlPanel from '../components/app/controlPanel';
import { sendAmplitudeEvent } from '../helpers/amplitude';

const updateProfileCb = (dispatch: any, payload: any) => dispatch(updateProfile(payload));
const setProfileProps = (dispatch: any, patient: Partial<IPatient>) =>
    dispatch(updatePatientActionCreator({ patient }));

const setControlPanelSwitch = (dispatch: any, control: Partial<IControl>, keys?: string[]) => {
    UiHelper.SetControlProps(dispatch, control, keys ? keys : undefined);

    let patientPayload: Partial<IPatient> = {};
    let patientPayloadDispatch = false;

    if (!UtilityHelper.IsNull(control.testKeepOffboard)) {
        if (control.testKeepOffboard === true) {
            patientPayload = {
                ...patientPayload,
                isOnboarded: !control.testKeepOffboard,
            };
            patientPayloadDispatch = true;
        }
    }

    if (!UtilityHelper.IsNull(control.testKeepOnboard)) {
        if (control.testKeepOnboard === true) {
            patientPayload = {
                ...patientPayload,
                isOnboarded: control.testKeepOnboard,
            };
            patientPayloadDispatch = true;
        }
    }

    if (!UtilityHelper.IsNull(control.testForcePending) || !UtilityHelper.IsNull(control.testForceRevoked)) {
        patientPayload = {
            ...patientPayload,
            isEnrolled: undefined,
            isUnenrolled: undefined,
            isOnboarded: undefined,
        };
        patientPayloadDispatch = true;
    }

    if (patientPayloadDispatch) {
        setProfileProps(dispatch, patientPayload);
    }
};

const signOut = async (keyToAllowSignOut: string, oktaAuth: OktaAuth) => {
    if (UtilityHelper.SettingGet(keyToAllowSignOut) !== '1') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setTimeout(async () => signOut(keyToAllowSignOut, oktaAuth), 1000);
    } else {
        await sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.LOG_OUT);
        await oktaAuth.signOut();
    }
};
const menuOpenCloseCb = async (
    home: IHome,
    dispatch: any,
    isMenuOpen: boolean,
    menuEntry: IMenuEntry,
    loginURL: string,
    oktaAuth: OktaAuth
) => {
    if (menuEntry?.key === MenuHelper.MenuEntryHome.key) {
        dispatch(anchorAllReportsActionCreator({ anchorAllReports: undefined }));
        dispatch(
            dashboardMetaDataActionCreator({
                dashboardBeg: undefined,
                dashboardEnd: undefined,
                dashboardHasReferrer: undefined,
            })
        );
    } else if (menuEntry?.key === MenuHelper.MenuEntrySignIn.key) {
        await UtilityHelper.redirectToLogin(loginURL);
    } else if (menuEntry?.key === MenuHelper.MenuEntrySignOut.key) {
        const keyToAllowSignOut = ConstantsHelper.IntersessionKeys.signOutCanProceed;

        UiHelper.SignOutArm(dispatch, keyToAllowSignOut);
        /* CAUTION:
            Do not dispatch any Redux actions before signout has been initiated as these actions
            may trigger a component refresh while signout has not been completed.  This could
            sometimes cause the session access token to refresh itself and in effect cancel the
            signout.
        */
        await signOut(keyToAllowSignOut, oktaAuth);
    } else if (menuEntry?.key === MenuHelper.MenuEntryTestMode.key) {
        setControlPanelSwitch(dispatch, { testMode: !home.control?.testMode }, [
            ConstantsHelper.IntersessionKeys.testMode,
        ]);
    }

    dispatch(isMenuOpenActionCreator({ isMenuOpen }));
};
let appInsights: any = null;
const aiProps = {
    after: (aiObject: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        appInsights = aiObject;
    },
};

const bemBlockName = 'app';

function App() {
    const history = useHistory();
    const dispatch = useDispatch();
    const homeDefault = useSelector((state: IState) => state.home);
    const home = SystemHelper.GetCleanState(homeDefault, homeInitialState);
    const { oktaAuth, endpointPwdUrlPrefix, endpointHcpUrlPrefix, endpointPdfUrlPrefix, loginURL } =
        MenuHelper.Init(home);
    const oktaData = home.authentication.oktaData;
    const control = home.control;
    const browserInfo = control.browserInfo;
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) =>
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const [readyToGo, setReadyToGo] = useState(UiHelper.IsSiteReadyToGo(home));

    useEffect(() => {
        let pollingTimer: ReturnType<typeof setInterval>;
        if (home.isPolling) {
            pollingTimer = setInterval(() => {
                dispatch(fetchProfile(null));
            }, 3000);
        }

        return () => {
            if (pollingTimer) {
                clearInterval(pollingTimer);
            }
        };
    }, [home.isPolling, dispatch]);

    useEffect(() => {
        dispatch(
            intersessionDataRestoreActionCreator({
                browserInfo: SystemHelper.GetBrowserInfo(),
                isProd: SystemHelper.IsProd(),
                pauseItEnabled: SystemHelper.IsPauseItEnabled(),
                testModeAllowed: SystemHelper.TestModeAllowed(),
            })
        );
        SystemHelper.AmplitudeInsightsConfig();
        dispatch(anI18NextLibActionCreator({ anI18Nextlib: AnI18NextLibHelper.loadLang('en') }));
        dispatch(endpointUrlPrefixActionCreator({ endpointPwdUrlPrefix, endpointHcpUrlPrefix, endpointPdfUrlPrefix }));
        MenuHelper.MapMenuLabels(home);
        MenuHelper.MapMenuComponents(home, pages);
        SystemHelper.AppInsightsEvent(
            home,
            'App',
            `Mounted -- environment:  ${control.isProd ? 'Prod' : 'Non-prod'} -- browser:  ${browserInfo.tag} is ${
                browserInfo.supported ? 'supported' : 'unsupportred'
            }`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const updateWindowSize = () =>
            UiHelper.SetControlProps(dispatch, {
                windowSize: {
                    height: window.innerHeight,
                    width: window.innerWidth,
                },
            });

        window.addEventListener('resize', updateWindowSize);
        updateWindowSize();
        return () => window.removeEventListener('resize', updateWindowSize);
    }, [dispatch]);

    const dismissToast = useCallback(() => SystemHelper.DismissError(dispatch), [dispatch]);

    const notifyErrorsIfAny = (homeHandle: IHome) =>
        Object.entries(homeHandle.errors).map((entry, idx) => (
            <Notify
                key={`error${idx}`}
                home={homeHandle}
                error={entry[1]}
                dismissToastCb={dismissToast}
                translate={translate}
            />
        ));

    const tokenLifeSecLeft = DateTimeHelper.GetSecToExpire(oktaData.expires).diff;

    useEffect(() => {
        const newReadyToGo = UiHelper.IsSiteReadyToGo(home);

        if (!readyToGo && newReadyToGo) {
            MenuHelper.MapMenuComponents(home, pages);
            setReadyToGo(true);
        }
    }, [readyToGo, home]);

    let onAuthRequired;

    if (home.control.pauseItEnabled) {
        onAuthRequired = async (_oktaAuth: OktaAuth) => {
            await sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.REDIRECT_TO_LOGIN, {
                redirectPage: _oktaAuth?.options.issuer || '/',
            });
            _oktaAuth?.signInWithRedirect({ originalUri: '/' });
        };
    } else {
        onAuthRequired = async () => {
            if (oktaData.isUserDefined) {
                await sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.LOG_OUT);
            }

            UtilityHelper.redirectToLogin(loginURL);
        };
    }

    const totalCallsToConsiderFetching = home.isPolling ? 2 : 1;
    const isFetching = home.loadingSemaphore >= totalCallsToConsiderFetching;
    const shouldDisableContent = isFetching || home.isMenuOpen;

    return (
        <TelemetryProvider {...aiProps}>
            <div className={clsx(styleGeneral.main, styleGuide.dashboard)}>
                {readyToGo || <Spinner className={styleGeneral.spinner} animation="border" role="status" />}

                {readyToGo && (
                    <>
                        <div
                            className={clsx({
                                [styleGeneral.show]: shouldDisableContent,
                                [styleGeneral.layerTopmost]: shouldDisableContent,
                                [styleGeneral.hide]: !shouldDisableContent,
                            })}
                            data-testid={`${bemBlockName}__overlay`}
                        />

                        {isFetching && (
                            <Spinner
                                className={styleGeneral.spinner}
                                data-testid={`${bemBlockName}__fetching-after-initial-fetch-spinner`}
                                animation="border"
                                role="status"
                            />
                        )}

                        {notifyErrorsIfAny(home)}

                        {control.testMode && (
                            <ControlPanel
                                home={home}
                                setControlPanelSwitchCb={({
                                    control1,
                                    keys,
                                }: {
                                    control1: Partial<IControl>;
                                    keys: string[];
                                }) => setControlPanelSwitch(dispatch, control1, keys)}
                                setProfileCb={(patient: Partial<IPatient>) => setProfileProps(dispatch, patient)}
                                updateProfileCb={({ control2 }: { control2: any }) =>
                                    updateProfileCb(dispatch, control2)
                                }
                            />
                        )}
                    </>
                )}

                <div className="App">
                    {home.allLoaded && (
                        <Security
                            oktaAuth={oktaAuth}
                            restoreOriginalUri={restoreOriginalUri}
                            onAuthRequired={onAuthRequired}
                        >
                            {readyToGo && (
                                <div
                                    className={UiHelper.GetClassNamesHeader(
                                        control.isProd,
                                        browserInfo.supported,
                                        styleGeneral.header,
                                        styleGeneral.headerAlt1,
                                        styleGeneral.headerAlt2
                                    )}
                                >
                                    <Header
                                        home={home}
                                        tokenLifeSecLeft={tokenLifeSecLeft}
                                        menuOpenCloseCb={({
                                            isMenuOpen,
                                            menuEntry,
                                        }: {
                                            isMenuOpen: boolean;
                                            menuEntry: IMenuEntry;
                                        }) =>
                                            menuOpenCloseCb(home, dispatch, isMenuOpen, menuEntry, loginURL, oktaAuth)
                                        }
                                        translate={translate}
                                    />
                                </div>
                            )}
                            <Switch>
                                {MenuHelper.MenuEntries.filter((e) => e.path).map((e: any) => {
                                    // TODO: Only render routes enabled for patient's device class.
                                    // That will require refactoring menu entry handling, menu entries should not be static.
                                    return e.nonSecure ? <Route {...e} /> : <SecureRoute {...e} />;
                                })}

                                <Redirect to="/error" />
                            </Switch>
                        </Security>
                    )}
                </div>
            </div>
        </TelemetryProvider>
    );
}

export default App;
