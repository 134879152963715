import React from 'react';
import clsx from 'clsx';

import {
    IBolusEntry,
    IBulletPoint,
    IChartEntryBloodGlucose,
    IChartPayload,
    IChartPayloadData,
    IControl,
    IHome,
    IReadingsInfo,
    ITranslator,
} from '../../types';
import styleGeneral from '../../styles/general.module.scss';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import UtilityHelper from '../../helpers/UtilityHelper';
import { DataItemModesPodStatuses, Mode, Submode } from '../../model/models';
import UiHelper from '../../helpers/UiHelper';

const dataRowEgv = ({
    dataEntry,
    idx,
    isSmoothingEntry,
    dataSeriesHigh,
    dataSeriesNormal,
    dataSeriesLow,
    mode,
    submode,
    podEntry,
    bolusEntry,
}: {
    dataEntry: IChartEntryBloodGlucose;
    idx: number;
    isSmoothingEntry: boolean;
    dataSeriesHigh: IChartEntryBloodGlucose[];
    dataSeriesNormal: IChartEntryBloodGlucose[];
    dataSeriesLow: IChartEntryBloodGlucose[];
    mode: Mode.ValueEnum;
    submode: Submode.ValueEnum;
    podEntry: IBulletPoint<DataItemModesPodStatuses.StatusEnum>;
    bolusEntry: IBolusEntry;
}) => (
    <tr className={clsx(styleGeneral.row, idx % 2 === 0 ? styleGeneral.even : styleGeneral.odd)} key={`row_egv_${idx}`}>
        <td className={clsx(styleGeneral.cell, styleGeneral.timestamp, styleGeneral.reading)}>{dataEntry.x}</td>
        <td
            className={clsx(
                styleGeneral.cell,
                styleGeneral.egv,
                styleGeneral.reading,
                isSmoothingEntry && styleGeneral.smoothing
            )}
        >
            {dataSeriesHigh[idx].y}
        </td>
        <td
            className={clsx(
                styleGeneral.cell,
                styleGeneral.egvWide,
                styleGeneral.reading,
                isSmoothingEntry && styleGeneral.smoothing
            )}
        >
            {dataSeriesNormal[idx].y}
        </td>
        <td
            className={clsx(
                styleGeneral.cell,
                styleGeneral.egv,
                styleGeneral.reading,
                isSmoothingEntry && styleGeneral.smoothing
            )}
        >
            {dataSeriesLow[idx].y}
        </td>
        <td className={styleGeneral.cell}>{bolusEntry?.y}</td>
        <td className={styleGeneral.cell}>{UtilityHelper.RoundNumberAsString(bolusEntry?.bolusMeal, 2)}</td>
        <td className={styleGeneral.cell}>{UtilityHelper.RoundNumberAsString(bolusEntry?.bolusCorrection, 2)}</td>
        <td className={styleGeneral.cell}>{UtilityHelper.RoundNumberAsString(bolusEntry?.bolusTotal, 2)}</td>
        <td className={styleGeneral.cell}>{UtilityHelper.RoundNumberAsString(bolusEntry?.carbs, 2)}</td>
        <td className={styleGeneral.cellLeft}>{bolusEntry?.bolusType}</td>
        <td className={styleGeneral.cellLeft}>{mode?.toString()}</td>
        <td className={styleGeneral.cellLeft}>{submode?.toString()}</td>
        <td className={styleGeneral.cellLeft}>{podEntry?.type?.toString()}</td>
    </tr>
);
const dataRowMode = (idx: number, isBeg: boolean, modeEntry: IBulletPoint<Mode.ValueEnum>, suffix: string) =>
    modeEntry && (
        <tr className={styleGeneral.row} key={`row_mode_${isBeg ? 'beg' : 'end'}_${idx}_${suffix}`}>
            <td className={clsx(styleGeneral.cell, styleGeneral.timestamp, styleGeneral.mode)}>
                {isBeg ? modeEntry.begTs : modeEntry.endTs}
            </td>
            <td className={clsx(styleGeneral.cell)} colSpan={9}></td>
            <td className={clsx(styleGeneral.cellLeft, styleGeneral.mode)}>{modeEntry?.type?.toString()}</td>
            <td className={styleGeneral.cellLeft} colSpan={2}></td>
        </tr>
    );
const dataRowSubMode = (idx: number, isBeg: boolean, submodeEntry: IBulletPoint<Submode.ValueEnum>, suffix: string) =>
    submodeEntry && (
        <tr className={styleGeneral.row} key={`row_submode_${isBeg ? 'beg' : 'end'}_${idx}_${suffix}`}>
            <td className={clsx(styleGeneral.cell, styleGeneral.timestamp, styleGeneral.submode)}>
                {isBeg ? submodeEntry.begTs : submodeEntry.endTs}
            </td>
            <td className={clsx(styleGeneral.cell)} colSpan={10}></td>
            <td className={clsx(styleGeneral.cellLeft, styleGeneral.submode)}>{submodeEntry?.type?.toString()}</td>
            <td className={styleGeneral.cellLeft}></td>
        </tr>
    );
const dataRowPod = (idx: number, podEntry: IBulletPoint<DataItemModesPodStatuses.StatusEnum>, suffix: string) =>
    podEntry && (
        <tr className={styleGeneral.row} key={`row_pod_${idx}_${suffix}`}>
            <td className={clsx(styleGeneral.cell, styleGeneral.timestamp, styleGeneral.pod)}>{podEntry.endTs}</td>
            <td className={styleGeneral.cell} colSpan={11}></td>
            <td className={clsx(styleGeneral.cellLeft, styleGeneral.pod)}>{podEntry?.type?.toString()}</td>
        </tr>
    );
const dataRowBolus = (idx: number, bolusEntry: IBolusEntry, suffix: string) => (
    <tr className={styleGeneral.row} key={`row_bolus_${idx}_${suffix}`}>
        <td className={clsx(styleGeneral.cell, styleGeneral.timestamp, styleGeneral.bolus)}>{bolusEntry.bolusTime}</td>
        <td className={clsx(styleGeneral.cell)} colSpan={3}></td>
        <td className={clsx(styleGeneral.cell, styleGeneral.bolus)}>{bolusEntry.y}</td>
        <td className={clsx(styleGeneral.cell, styleGeneral.bolus)}>
            {UtilityHelper.RoundNumberAsString(bolusEntry.bolusMeal, 2)}
        </td>
        <td className={clsx(styleGeneral.cell, styleGeneral.bolus)}>
            {UtilityHelper.RoundNumberAsString(bolusEntry.bolusCorrection, 2)}
        </td>
        <td className={clsx(styleGeneral.cell, styleGeneral.bolus)}>
            {UtilityHelper.RoundNumberAsString(bolusEntry.bolusTotal, 2)}
        </td>
        <td className={clsx(styleGeneral.cell, styleGeneral.bolusWide)}>
            {UtilityHelper.RoundNumberAsString(bolusEntry.carbs, 2)}
        </td>
        <td className={clsx(styleGeneral.cellLeft, styleGeneral.bolusWide)}>{bolusEntry.bolusType}</td>
        <td className={styleGeneral.cell} colSpan={3}></td>
    </tr>
);
const drawHeader = (
    translate: ITranslator,
    readingsInfo: IReadingsInfo,
    dataSeriesNormal: IChartEntryBloodGlucose[]
) => (
    <thead>
        <tr key={'rowHead0'} className={styleGeneral.rowHeaderFirst}>
            <th
                className={clsx(styleGeneral.cellHeader, styleGeneral.timestamp, styleGeneral.reading)}
                data-testid="idTimestamp"
            >
                Readings count: {dataSeriesNormal?.length}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.reading)} colSpan={3} data-testid="idEgv">
                EGV:{'  '}
                {UiHelper.GetOnOffSuffix(readingsInfo.showRecsEgv)}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolus)} colSpan={6} data-testid="idBolus">
                Bolus:{'  '}
                {UiHelper.GetOnOffSuffix(readingsInfo.showRecsBolus)}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.mode)} data-testid="idMode">
                Mode:{'  '}
                {UiHelper.GetOnOffSuffix(readingsInfo.showRecsMode)}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.submode)} data-testid="idSubmode">
                Submode:{'  '}
                {UiHelper.GetOnOffSuffix(readingsInfo.showRecsSubMode)}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.pod)} data-testid="idPod">
                Pod:{'  '}
                {UiHelper.GetOnOffSuffix(readingsInfo.showRecsPod)}
            </th>
        </tr>
        <tr key={'rowHead1'} className={styleGeneral.rowHeader}>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.timestamp, styleGeneral.reading)}>
                {translate('timestamp')}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.egv, styleGeneral.reading)}>
                {translate('high')}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.egvWide, styleGeneral.reading)}>
                {translate('inRange')}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.egv, styleGeneral.reading)}>
                {translate('low')}
            </th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolus)}>{translate('bolusY')}</th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolus)}>{translate('bolusMeal')}</th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolus)}>{translate('bolusCorr')}</th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolus)}>{translate('bolusTotal')}</th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolusWide)}>{translate('carbs')}</th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.bolusWide)}>{translate('bolusType')}</th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.mode)}></th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.submode)}></th>
            <th className={clsx(styleGeneral.cellHeader, styleGeneral.pod)}></th>
        </tr>
    </thead>
);

let dataEntryLast: IChartEntryBloodGlucose;
let modeEntryLast: IBulletPoint<Mode.ValueEnum>;
let submodeEntryLast: IBulletPoint<Submode.ValueEnum>;

const prepModeSubmodeEntries = (
    dataEntry: IChartEntryBloodGlucose,
    chartPayloadInsulinDelivery: IChartPayload
): { modeEntryActive: IBulletPoint<Mode.ValueEnum>; submodeEntryActive: IBulletPoint<Submode.ValueEnum> } => {
    const modeEntryActive = chartPayloadInsulinDelivery?.trackerRange?.find(
        (rangeEntry) => rangeEntry.begTs <= dataEntry.x && rangeEntry.endTs >= dataEntry.x
    );
    const submodeEntryActive = chartPayloadInsulinDelivery?.trackerMeasure?.find(
        (measureEntry) => measureEntry.begTs <= dataEntry.x && measureEntry.endTs >= dataEntry.x
    );

    return { modeEntryActive, submodeEntryActive };
};
const prepPodEntries = (
    dataEntry: IChartEntryBloodGlucose,
    idx: number,
    chartPayloadInsulinDelivery: IChartPayload,
    bloodGlucoseDataAttributes: IChartPayload,
    dataSeriesNormal: IChartEntryBloodGlucose[]
): {
    podEntries: IBulletPoint<DataItemModesPodStatuses.StatusEnum>[];
    podEntriesLast: IBulletPoint<DataItemModesPodStatuses.StatusEnum>[];
} => {
    const podEntries: IBulletPoint<DataItemModesPodStatuses.StatusEnum>[] =
        chartPayloadInsulinDelivery?.trackerMark?.filter(
            (markEntry) =>
                markEntry.type !== ConstantsHelper.DataSubTypeTopOfHour &&
                markEntry.endTs >= (dataEntryLast?.x ?? bloodGlucoseDataAttributes.windowBeg) &&
                markEntry.endTs < dataEntry.x
        ) as IBulletPoint<DataItemModesPodStatuses.StatusEnum>[];
    const podEntriesLast: IBulletPoint<DataItemModesPodStatuses.StatusEnum>[] =
        idx === dataSeriesNormal.length - 1
            ? (chartPayloadInsulinDelivery?.trackerMark?.filter(
                  (markEntry) =>
                      markEntry.type !== ConstantsHelper.DataSubTypeTopOfHour && markEntry.endTs >= dataEntry.x
              ) as IBulletPoint<DataItemModesPodStatuses.StatusEnum>[])
            : [];

    return {
        podEntries,
        podEntriesLast,
    };
};
const prepBolusEntries = (
    dataEntry: IChartEntryBloodGlucose,
    idx: number,
    readingsWithBoluses: IChartEntryBloodGlucose[],
    bloodGlucoseDataAttributes: IChartPayload,
    dataSeriesNormal: IChartEntryBloodGlucose[]
): {
    bolusEntries: IBolusEntry[];
    bolusEntriesLast: IBolusEntry[];
} => {
    const bolusEntries: IBolusEntry[] = [];
    const bolusEntriesLast: IBolusEntry[] = [];

    readingsWithBoluses.forEach((readingWithBolusEntries) => {
        readingWithBolusEntries.boluses
            .filter(
                (bolusEntry) =>
                    bolusEntry.bolusTime >= (dataEntryLast?.x ?? bloodGlucoseDataAttributes.windowBeg) &&
                    bolusEntry.bolusTime < dataEntry.x
            )
            .forEach((bolusEntry) => {
                bolusEntries.push({
                    ...bolusEntry,
                    y: readingWithBolusEntries.y,
                } as IBolusEntry);
            });
    });

    if (idx === dataSeriesNormal.length - 1) {
        readingsWithBoluses.forEach((readingWithBolusEntries) => {
            readingWithBolusEntries.boluses
                .filter((bolusEntry) => bolusEntry.bolusTime > dataEntry.x)
                .forEach((bolusEntry) => {
                    bolusEntriesLast.push({
                        ...bolusEntry,
                        y: readingWithBolusEntries.yBolus,
                    } as IBolusEntry);
                });
        });
    }

    return {
        bolusEntries,
        bolusEntriesLast,
    };
};
const drawRow = ({
    dataEntry,
    idx,
    dataSeriesHigh,
    dataSeriesNormal,
    dataSeriesLow,
    isSmoothingEntry,
    modeEntryActive,
    submodeEntryActive,
    podEntries,
    podEntriesLast,
    bolusEntries,
    bolusEntriesLast,
    showRecsMode,
    showRecsSubMode,
    showRecsPod,
    showRecsBolus,
    showRecsEgv,
    modeEntryEnding,
    submodeEntryEnding,
}: {
    dataEntry: IChartEntryBloodGlucose;
    idx: number;
    dataSeriesHigh: IChartEntryBloodGlucose[];
    dataSeriesNormal: IChartEntryBloodGlucose[];
    dataSeriesLow: IChartEntryBloodGlucose[];
    isSmoothingEntry: boolean;
    modeEntryActive: IBulletPoint<Mode.ValueEnum>;
    submodeEntryActive: IBulletPoint<Submode.ValueEnum>;
    podEntries: IBulletPoint<DataItemModesPodStatuses.StatusEnum>[];
    podEntriesLast: IBulletPoint<DataItemModesPodStatuses.StatusEnum>[];
    bolusEntries: IBolusEntry[];
    bolusEntriesLast: IBolusEntry[];
    showRecsMode: boolean;
    showRecsSubMode: boolean;
    showRecsPod: boolean;
    showRecsBolus: boolean;
    showRecsEgv: boolean;
    modeEntryEnding: IBulletPoint<Mode.ValueEnum>;
    submodeEntryEnding: IBulletPoint<Submode.ValueEnum>;
}) => (
    <tbody key={`rowSet${idx}`}>
        {showRecsMode &&
            modeEntryActive?.endTs !== modeEntryEnding?.endTs &&
            modeEntryActive?.begTs !== modeEntryEnding?.endTs &&
            dataRowMode(idx, false, modeEntryEnding, 'b')}
        {showRecsSubMode &&
            submodeEntryActive?.endTs !== submodeEntryEnding?.endTs &&
            submodeEntryActive?.begTs !== submodeEntryEnding?.endTs &&
            dataRowSubMode(idx, false, submodeEntryEnding, 'b')}
        {showRecsMode &&
            modeEntryActive?.begTs !== modeEntryEnding?.begTs &&
            dataRowMode(idx, true, modeEntryActive, 'b')}
        {showRecsSubMode &&
            submodeEntryActive?.begTs !== submodeEntryEnding?.begTs &&
            dataRowSubMode(idx, true, submodeEntryActive, 'b')}
        {showRecsPod && podEntries.map((podEntry) => dataRowPod(idx, podEntry, 'b'))}
        {showRecsBolus && bolusEntries.map((bolusEntry) => dataRowBolus(idx, bolusEntry, 'b'))}
        {showRecsEgv &&
            dataRowEgv({
                dataEntry,
                idx,
                isSmoothingEntry,
                dataSeriesHigh,
                dataSeriesNormal,
                dataSeriesLow,
                mode: modeEntryActive?.type,
                submode: submodeEntryActive?.type,
                podEntry: podEntries.length > 0 ? podEntries[podEntries.length - 1] : null,
                bolusEntry: bolusEntries.length > 0 ? bolusEntries[bolusEntries.length - 1] : null,
            })}
        {showRecsMode &&
            modeEntryActive &&
            idx === dataSeriesNormal.length - 1 &&
            dataRowMode(idx, false, modeEntryActive, 'c')}
        {showRecsSubMode &&
            submodeEntryActive &&
            idx === dataSeriesNormal.length - 1 &&
            dataRowSubMode(idx, false, submodeEntryActive, 'c')}
        {showRecsPod && podEntriesLast.map((podEntry) => dataRowPod(idx, podEntry, 'c'))}
        {showRecsBolus && bolusEntriesLast.map((bolusEntry) => dataRowBolus(idx, bolusEntry, 'c'))}
    </tbody>
);
const dataTableRows = ({
    dataEntry,
    idx,
    control,
    readingsInfo,
    bloodGlucoseDataAttributes,
    chartPayloadInsulinDelivery,
    dataSeriesHigh,
    dataSeriesNormal,
    dataSeriesLow,
    readingsWithBoluses,
}: {
    dataEntry: IChartEntryBloodGlucose;
    idx: number;
    control: IControl;
    readingsInfo: IReadingsInfo;
    bloodGlucoseDataAttributes: IChartPayload;
    chartPayloadInsulinDelivery: IChartPayload;
    dataSeriesHigh: IChartEntryBloodGlucose[];
    dataSeriesNormal: IChartEntryBloodGlucose[];
    dataSeriesLow: IChartEntryBloodGlucose[];
    readingsWithBoluses: IChartEntryBloodGlucose[];
}) => {
    const isSmoothingEntry =
        (dataSeriesHigh[idx].y > 0 ? 1 : 0) +
            (dataSeriesNormal[idx].y > 0 ? 1 : 0) +
            (dataSeriesLow[idx].y > 0 ? 1 : 0) >
        1;
    const { modeEntryActive, submodeEntryActive } = prepModeSubmodeEntries(dataEntry, chartPayloadInsulinDelivery);
    const { podEntries, podEntriesLast } = prepPodEntries(
        dataEntry,
        idx,
        chartPayloadInsulinDelivery,
        bloodGlucoseDataAttributes,
        dataSeriesNormal
    );
    const { bolusEntries, bolusEntriesLast } = prepBolusEntries(
        dataEntry,
        idx,
        readingsWithBoluses,
        bloodGlucoseDataAttributes,
        dataSeriesNormal
    );
    const modeEntryEnding = modeEntryLast;
    const submodeEntryEnding = submodeEntryLast;

    dataEntryLast = dataEntry;
    modeEntryLast = modeEntryActive;
    submodeEntryLast = submodeEntryActive;

    const showRecsMode = control.testTips && readingsInfo.showRecsMode;
    const showRecsSubMode = control.testTips && readingsInfo.showRecsSubMode;
    const showRecsPod = control.testTips && readingsInfo.showRecsPod;
    const showRecsBolus = control.testTips && readingsInfo.showRecsBolus;
    const showRecsEgv = control.testTips && readingsInfo.showRecsEgv;

    return drawRow({
        dataEntry,
        idx,
        dataSeriesHigh,
        dataSeriesNormal,
        dataSeriesLow,
        isSmoothingEntry,
        modeEntryActive,
        submodeEntryActive,
        podEntries,
        podEntriesLast,
        bolusEntries,
        bolusEntriesLast,
        showRecsMode,
        showRecsSubMode,
        showRecsPod,
        showRecsBolus,
        showRecsEgv,
        modeEntryEnding,
        submodeEntryEnding,
    });
};
const dataTable = (
    translate: ITranslator,
    control: IControl,
    readingsInfo: IReadingsInfo,
    bloodGlucoseDataAttributes: IChartPayload,
    chartPayloadInsulinDelivery: IChartPayload
) => {
    const dataBg: IChartPayloadData[] = bloodGlucoseDataAttributes?.data;
    const dataSeriesHigh: IChartEntryBloodGlucose[] = dataBg?.find(
        (e) => e.id === ConstantsHelper.DataSeriesHigh
    )?.data;
    const dataSeriesNormal: IChartEntryBloodGlucose[] = dataBg?.find(
        (e) => e.id === ConstantsHelper.DataSeriesNormal
    )?.data;
    const dataSeriesLow: IChartEntryBloodGlucose[] = dataBg?.find((e) => e.id === ConstantsHelper.DataSeriesLow)?.data;
    const dataSeriesBolus: IChartEntryBloodGlucose[] = dataBg?.find(
        (e) => e.id === ConstantsHelper.DataSeriesBolus
    )?.data;
    const readingsWithBoluses = dataSeriesBolus?.filter((e) => e.boluses?.length > 0);

    return (
        <table className={styleGeneral.table}>
            {drawHeader(translate, readingsInfo, dataSeriesNormal)}
            {dataSeriesNormal?.map((dataEntry, idx) =>
                dataTableRows({
                    dataEntry,
                    idx,
                    control,
                    readingsInfo,
                    bloodGlucoseDataAttributes,
                    chartPayloadInsulinDelivery,
                    dataSeriesHigh,
                    dataSeriesNormal,
                    dataSeriesLow,
                    readingsWithBoluses,
                })
            )}
        </table>
    );
};

function ContributingEventDataTable({
    home,
    bloodGlucoseDataAttributes,
    insulinDeliveryDataAttributes,
    translate,
}: {
    home: IHome;
    bloodGlucoseDataAttributes: IChartPayload;
    insulinDeliveryDataAttributes: IChartPayload;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const readingsInfo = control.readingsInfo ?? ({} as IReadingsInfo);

    return !(control.testTips && control.testMode) ? null : (
        <div className={clsx(styleGeneral.dataTable, styleGeneral.testModeOn)}>
            <div className={readingsInfo.showTable && styleGeneral.showData}>
                {readingsInfo.showTable && (
                    <div className={styleGeneral.frame}>
                        {dataTable(
                            translate,
                            control,
                            readingsInfo,
                            bloodGlucoseDataAttributes,
                            insulinDeliveryDataAttributes
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ContributingEventDataTable;
