import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { IState } from '../types';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import Identification from '../components/common/identificiation';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import { homeInitialState } from '../store/home';
import iconHeadquarters from '../assets/images/headquarters-icon.svg';

function About() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const streetAddress = SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_COMPANY_ADDRESS');
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'About()', 'Mounted');
    }, [home]);
    useEffect(() => {
        if (!home.patient.receivedData) {
            UiHelper.FetchBackEndId(dispatch, home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        home.patient.receivedData,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment translate={translate}>
            <div
                className={UiHelper.GetClassNamesHeader(
                    home.control.isProd,
                    home.control.browserInfo?.supported,
                    styleGeneral.body,
                    styleGeneral.bodyAlt1,
                    styleGeneral.bodyAlt2
                )}
            >
                <div className={styleGuide.about}>
                    <div className={styleGuide.heading}>{translate('about.title')}</div>
                    <div className={styleGuide.body}>
                        <div className={styleGuide.group}>
                            <div className={styleGuide.label}>{translate('constants.appName')}</div>
                            <div className={styleGuide.label}>{translate('about.subTitle')}</div>
                        </div>
                        <div className={styleGuide.group}>
                            <div className={styleGuide.label}>{translate('about.version')}</div>
                            <div className={styleGuide.value}>
                                {SystemHelper.GetRuntimeConfig('REACT_APP_VERSION', '--')}
                            </div>
                        </div>
                        <div className={styleGuide.group}>
                            <div className={styleGuide.label}>{translate('about.udi')}</div>
                            <div className={styleGuide.value}>
                                (01){SystemHelper.GetRuntimeConfig('REACT_APP_DEVICE_IDENTIFIER', '--')}(10)
                                {SystemHelper.GetRuntimeConfig('REACT_APP_VERSION', '--')}
                            </div>
                        </div>
                        <div className={styleGuide.groupAlt}>
                            <a
                                className={styleGeneral.link}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LEGAL')}
                                target="__EULA__"
                            >
                                {translate('about.eulaInformation')}
                            </a>
                        </div>
                        <div className={styleGuide.groupAlt}>
                            <a
                                className={styleGeneral.link}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_COOKIE_POLICY')}
                                target="__COOKIE__"
                            >
                                {translate('about.cookiePolicy')}
                            </a>
                        </div>
                    </div>
                    {!home.control.pauseItEnabled && (
                        <div className={styleGuide.body} data-testid={'podderCentral-section'}>
                            <div className={styleGuide.group}>
                                <div className={styleGuide.label}>
                                    {translate('about.unenrollPrefixText')}
                                    <span className={styleGuide.labelAlt}>®</span>&nbsp;
                                    {translate('about.unenrollSuffixText')}
                                </div>
                            </div>
                            <div className={styleGuide.group}>
                                <div className={styleGuide.label}>
                                    <a
                                        className={styleGeneral.link}
                                        href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_UNROLL_URL_LINK')}
                                        target="__UNENROLL__"
                                        data-testid="link-item"
                                    >
                                        {SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_UNROLL_URL_LABEL')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styleGuide.body} data-testid={'aboutAddress-section'}>
                        <div className={styleGuide.groupAlt}>
                            <div className={styleGeneral.table}>
                                <div className={styleGeneral.row}>
                                    <div className={clsx(styleGeneral.cell, styleGuide.left)}>
                                        <div className={styleGuide.image}>
                                            <img src={iconHeadquarters} alt={translate('about.headquarters')} />
                                        </div>
                                    </div>
                                    <div className={clsx(styleGeneral.cell, styleGuide.right)}>
                                        <div className={styleGuide.text}>
                                            <div>{SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_COMPANY_NAME')}</div>
                                            <div>
                                                <a
                                                    className={clsx(styleGeneral.link, styleGuide.link)}
                                                    href={`http://maps.google.com/?q=${streetAddress}`}
                                                    target="__STREET_ADDRESS__"
                                                >
                                                    {streetAddress}
                                                </a>
                                            </div>
                                            {!home.control.pauseItEnabled && (
                                                <>
                                                    <div>
                                                        <a
                                                            className={clsx(styleGeneral.link, styleGuide.link)}
                                                            data-testid="phone-number-1"
                                                            href={SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER1_LINK'
                                                            )}
                                                        >
                                                            {SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER1_LABEL'
                                                            )}
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a
                                                            className={clsx(styleGeneral.link, styleGuide.link)}
                                                            data-testid="phone-number-2"
                                                            href={SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER2_LINK'
                                                            )}
                                                        >
                                                            {SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER2_LABEL'
                                                            )}
                                                        </a>
                                                    </div>
                                                </>
                                            )}
                                            <div>
                                                <a
                                                    className={clsx(styleGeneral.link, styleGuide.link)}
                                                    href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LINK')}
                                                    target="__COMPANY_HOME__"
                                                >
                                                    {SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LABEL')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Identification home={home} translate={translate} />
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default About;
