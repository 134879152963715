import React from 'react';
import clsx from 'clsx';
import { ResponsivePie } from '@nivo/pie';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IInsight3, ITranslator } from '../../../types';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import arrowByValueRange from '../../common/arrowByValueRange';
import classByValueRange from '../../common/classByValueRange';

function InsightSummaryModeUsage({ summary, translate }: { summary: IInsight3; translate: ITranslator }) {
    const getChartSettings = (data: any): any => {
        return {
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            indexBy: 'index',
            innerRadius: 0.8,
            cornerRadius: 20,
            padAngle: 2,
            enableLabel: false,
            arcLabelsTextColor: 'transparent',
            labelTextColor: 'inherit:darker(1.4)',
            labelSkipWidth: 16,
            labelSkipHeight: 16,
            animate: true,
            axisBottom: null,
            axisLeft: null,
            axisRight: null,
            axisTop: null,
            colors: ['#546a7c', '#006fdd', '#5b34b9'],
            data,
            enableArcLinkLabels: false,
            enablePointLabel: false,
            xScale: null,
            yScale: null,
        };
    };

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={styleGuide.whiteBackground}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.modeUsage}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{translate('modeUsage')}</div>
                                        </div>
                                        <div className={styleGuide.subTitle}>
                                            {translate('podWasActiveThisWeek', {
                                                value: summary.activePct,
                                            })}
                                        </div>
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span
                                        className={clsx(
                                            styleGuide.rightTable,
                                            classByValueRange(summary.activePctDelta)
                                        )}
                                    >
                                        <span className={styleGuide.icon}>
                                            <img src={arrowByValueRange(summary.activePctDelta)} alt="arrow icon" />
                                        </span>
                                        <span className={styleGuide.text}>{Math.abs(summary.activePctDelta)}%</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row2)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={styleGuide.top}>{summary.timeInModeAutoPct}%</span>
                                    <span className={styleGuide.middle}>
                                        {translate(ConstantsHelper.ReadingsLabelAutomated)} {translate('mode')}
                                    </span>
                                    <span className={styleGuide.bottom}>
                                        <div className={clsx(styleGeneral.table)}>
                                            <div className={styleGeneral.row}>
                                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                                    <span className={clsx(styleGuide.caption)}>
                                                        {translate(ConstantsHelper.ReadingsLabelLimited)}
                                                    </span>
                                                    <span className={clsx(styleGuide.value, styleGuide.low)}>
                                                        {summary.timeInModeLimitedPct}%
                                                    </span>
                                                </span>
                                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                                    <span className={clsx(styleGuide.caption)}>
                                                        {translate(ConstantsHelper.ReadingsLabelManual)}
                                                    </span>
                                                    <span className={clsx(styleGuide.value, styleGuide.high)}>
                                                        {summary.timeInModeManualPct}%
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={styleGuide.chart}>
                                        <ResponsivePie
                                            {...getChartSettings([
                                                {
                                                    id: ConstantsHelper.DataSeriesLimited,
                                                    value: summary.timeInModeLimitedPct,
                                                },
                                                {
                                                    id: ConstantsHelper.DataSeriesManual,
                                                    value: summary.timeInModeManualPct,
                                                },
                                                {
                                                    id: ConstantsHelper.DataSeriesAutomated,
                                                    value: summary.timeInModeAutoPct,
                                                },
                                            ])}
                                        />
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryModeUsage;
