import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import UtilityHelper from '../helpers/UtilityHelper';
import SystemHelper from '../helpers/SystemHelper';
import {
    anchorEventActionCreator,
    dashboardMetaDataActionCreator,
    insightIdActionCreator,
} from '../store/home/home.slice';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import { IState } from '../types';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import { sendAmplitudeEvent } from '../helpers/amplitude';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import { homeInitialState } from '../store/home';
import DateTimeHelper from '../helpers/DateTimeHelper';
import LoyaltyInsulinUsageBlock from '../components/dashboard/loyalty/loyaltyInsulinUsageBlock';
import LoyaltyInsulinModeUsageChart from '../components/dashboard/loyalty/LoyaltyInsulinModeUsageChart';
import LoyaltyInsulinUsageAvgDaily from '../components/dashboard/loyalty/loyaltyInsulinUsageAvgDaily';
import LoyaltyInsulinUsageWeekly from '../components/dashboard/loyalty/loyaltyInsulinUsageWeekly';
import LoyaltyInsulinUsageFactors from '../components/dashboard/loyalty/loyaltyInsulinUsageFactors';

function LoyaltyInsulinUsageInsights(props: any) {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const control = home.control;
    const browserInfo = control.browserInfo;
    const patient = home.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer, insightId } = props?.match?.params ?? {};
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );

    useEffect(() => {
        if (
            !(
                home.dashboardBeg === dashboardBeg &&
                home.dashboardEnd === dashboardEnd &&
                home.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    useEffect(() => {
        if (home.insightId !== insightId) {
            dispatch(insightIdActionCreator({ insightId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightId]);

    const dashboard = home.currentDashboard;
    const insight = home.cachedInsights[home.insightId];
    const clearAnchorEvent = () => dispatch(anchorEventActionCreator({ anchorEvent: undefined }));
    const [scrollActive1, setScrollActive1] = useState(false);

    useLayoutEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'InsightDetails()', 'Mounted');
        sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.VIEW_INSULIN_USAGE_DETAILS, {
            weekOf: home?.dashboardBeg,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (patient.receivedData && !(home.dashboardEnd && home.insightId && dashboard?.insightIds)) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, home);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, home);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        patient.receivedData,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    useEffect(() => {
        if (home.anchorEvent && insight) {
            NavigationHelper.ScrollIntoView(home.anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.anchorEvent, insight]);

    const shouldDisplayInsulinUsageInformation = useMemo(() => {
        const insulinUsageWeeks = UtilityHelper.ToNumeric(
            SystemHelper.GetRuntimeConfig('REACT_APP_INSULIN_USAGE_WEEKS')
        );
        return home.currentInsulinUsage?.weeklyTrend?.weeks?.length < insulinUsageWeeks;
    }, [home.currentInsulinUsage]);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment={false} translate={translate}>
            {home?.currentInsulinUsage?.dailyUsage && (
                <div
                    data-testid="loyalty-congrats-container"
                    className={UiHelper.GetClassNamesHeader(
                        control.isProd,
                        browserInfo.supported,
                        styleGeneral.body,
                        styleGeneral.bodyAlt1,
                        styleGeneral.bodyAlt2
                    )}
                    onScroll={(event) => handleScroll(event)}
                >
                    <div className={styleGeneral.stickToTopBack}>
                        <BtnBack
                            url={NavigationHelper.GenUrlDashboard(
                                home.dashboardBeg,
                                home.dashboardEnd,
                                home.dashboardHasReferrer
                            )}
                        />
                    </div>
                    <div
                        className={clsx(styleGuide.report, styleGuide.solidBgWhite, styleGeneral.stickToTopLevel2)}
                        data-testid="loyalty-insulin"
                    >
                        <div
                            className={clsx(
                                styleGuide.header,
                                styleGuide.solidBgWhite,
                                styleGeneral.dashboardZoomInScroll1OffScroll2Off
                            )}
                        >
                            <div className={clsx(styleGuide.loyaltyTitle, scrollActive1 && styleGeneral.inScroll2)}>
                                {translate('loyalty.insulin.title')}
                            </div>
                            <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                                <div className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}>
                                    {DateTimeHelper.FormatDateRange(home.dashboardEnd)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styleGuide.solidBg} data-testid="loyalty-insulin-frame">
                        <div className={clsx(styleGuide.insightsFullWidthFirst, styleGeneral.dashboardSummary)}>
                            <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                                <div className={styleGuide.whiteBackground}>
                                    <LoyaltyInsulinUsageBlock
                                        translate={translate}
                                        tokenHeader={'loyalty.insulin.weeklyTrend.title'}
                                        tokenBody={'loyalty.insulin.weeklyTrend.block'}
                                        preSeparate={false}
                                    />
                                    <LoyaltyInsulinUsageWeekly home={home} translate={translate} />
                                </div>
                            </div>
                        </div>
                        <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                            <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                                <div className={styleGuide.whiteBackground}>
                                    <LoyaltyInsulinUsageBlock
                                        translate={translate}
                                        tokenHeader={'loyalty.insulin.totalDailyInsulinRatio.title'}
                                        tokenInfo={'loyalty.insulin.totalDailyInsulinRatio.info'}
                                        tokenBody={
                                            shouldDisplayInsulinUsageInformation
                                                ? 'loyalty.insulin.totalDailyInsulinRatio.totalDailyInsulinBlock'
                                                : ''
                                        }
                                        weekOf={home?.dashboardBeg}
                                    />
                                    <LoyaltyInsulinModeUsageChart home={home} translate={translate} showUnits />
                                </div>
                            </div>
                        </div>
                        <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                            <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                                <div className={styleGuide.whiteBackground}>
                                    <div className={styleGuide.header}>
                                        <LoyaltyInsulinUsageBlock
                                            translate={translate}
                                            tokenHeader={'loyalty.insulin.avgDailyBolus.title'}
                                            tokenBody={
                                                shouldDisplayInsulinUsageInformation
                                                    ? 'loyalty.insulin.avgDailyBolus.block'
                                                    : ''
                                            }
                                            preSeparate={false}
                                        />
                                        <LoyaltyInsulinUsageAvgDaily home={home} translate={translate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoyaltyInsulinUsageFactors home={home} translate={translate} />
                </div>
            )}
        </ContentWrapperAll>
    );
}

export default LoyaltyInsulinUsageInsights;
