import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { IState } from '../types';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import { sendAmplitudeEvent } from '../helpers/amplitude';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import BtnPrimary from '../components/common/btnPrimary';
import ConstantsHelper from '../helpers/ConstantsHelper';
import StringHelper from '../helpers/StringHelper';
import UiHelper from '../helpers/UiHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import { fetchHcpReport } from '../store/home/home.slice';
import { homeInitialState } from '../store/home';

export default function Download() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const control = home.control;
    const patient = home.patient;
    const browserInfo = control.browserInfo;
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const daysMax = UtilityHelper.ReportDaysAvailableGet(home.patient);
    const daySelectionsAll = [
        { value: 7, show: daysMax >= 7 },
        { value: 14, show: daysMax >= 14 },
        { value: 30, show: daysMax >= 30 },
        { value: 60, show: daysMax >= 60 },
        { value: 90, show: daysMax >= 90 },
    ];
    const daySelections = daySelectionsAll.filter((daySelection) => daySelection.show);
    const [radioValue, setRadioValue] = useState(0);
    const [radioValueMax, setRadioMaxValue] = useState(0);
    const downloadReportCb = (newRadioValue: number) => {
        const webUrl = home.endpointHcpUrlPrefix;
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const encodeUrlOnReport = encodeURIComponent(
            `/report?days=${newRadioValue}&displayOnPrintOnly=true&timeZone=${timeZone}`
        );
        const base64url = btoa(`${webUrl}?path=${encodeUrlOnReport}`);
        const urlParams = [
            `url=${base64url}`,
            `token=${home.authentication.oktaData.access}`,
            'loadingIndicatorSelector=.loading-content',
        ];
        const url = `${home.endpointPdfUrlPrefix}/generate?${urlParams.join('&')}`;

        dispatch(fetchHcpReport({ numDays: newRadioValue }));
        window.open(url, '__HCP_REPORT__', 'noopener,noreferrer');

        sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.DOWNLOAD_PHYSICIAN_REPORT, {
            numDays: `${newRadioValue}`,
            // Note:  Do not use UtilityHelper.GetDeviceClassName() to ensure the same device type is reported regardless of language selected
            deviceType: home.patient?.deviceClass,
            // Note:  Do not translate user type to ensure same set of values are reported regardless of language selected
            user: 'PWD',
        });
    };

    useEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'Download()', 'Mounted');
    }, [home]);

    useEffect(() => {
        if (patient.receivedData && patient.reportDaysAvailableString) {
            const newValue = daySelections.length > 0 ? daySelections[daySelections.length - 1]?.value : 0;

            setRadioValue(newValue);
            setRadioMaxValue(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.receivedData, patient.reportDaysAvailableString]);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment={false} translate={translate}>
            <div
                className={clsx(
                    UiHelper.GetClassNamesHeader(
                        control.isProd,
                        browserInfo.supported,
                        styleGeneral.body,
                        styleGeneral.bodyAlt1,
                        styleGeneral.bodyAlt2
                    ),
                    styleGuide.download
                )}
            >
                <div className={clsx(styleGuide.heading)}>{translate('download.header')}</div>
                {daySelections.length > 0 ? (
                    <div className={clsx(styleGuide.subHeading)}>
                        {translate('download.bodyData')}
                        <div className={styleGuide.daySelections}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={radioValueMax}
                                    value={radioValue}
                                    name="radio-buttons-group"
                                    onChange={(event, value) => setRadioValue(Number(value))}
                                >
                                    {daySelections.map((daySelection, idx) => (
                                        <FormControlLabel
                                            className={styleGuide.daySelection}
                                            key={`daySelection${idx}`}
                                            data-testid={`daySelection${idx}`}
                                            value={daySelection.value}
                                            control={<Radio />}
                                            label={StringHelper.Pluralize(daySelection.value, 'Day')}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                ) : (
                    <div className={clsx(styleGuide.subHeading)}>
                        {translate('download.bodyLessThanMinimumData', {
                            to: daySelectionsAll[daySelectionsAll.length - 1].value,
                        })}
                    </div>
                )}
                <div>
                    <BtnPrimary
                        caption={translate('download.downloadReport')}
                        disabled={daysMax === 0}
                        cbClickFn={() => downloadReportCb(daySelections.length > 0 ? radioValue : daysMax)}
                    />
                </div>
            </div>
        </ContentWrapperAll>
    );
}
