import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Menu from './menu';

import MenuHelper from '../../helpers/MenuHelper';
import { ICallback, IControl, IHome, IMenuEntry, ITranslator } from '../../types';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import 'react-datepicker/dist/react-datepicker.css';
import '../../app/App.scss';

function Header({
    home,
    tokenLifeSecLeft,
    menuOpenCloseCb,
    translate,
}: {
    home: IHome;
    tokenLifeSecLeft: number;
    menuOpenCloseCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const authState = useOktaAuth()?.authState;
    const menuEntries = MenuHelper.MenuEntries.filter(
        (e) =>
            (authState?.isAuthenticated &&
                !e.hidden &&
                !e.disabled &&
                !(e.key === MenuHelper.MenuEntryTestMode.key || e.testModeOnly)) ||
            (!authState?.isAuthenticated && e.key === MenuHelper.MenuEntrySignIn.key) ||
            (control.testModeAllowed &&
                (e.key === MenuHelper.MenuEntryTestMode.key || (control.testMode && e.testModeOnly)))
    );

    return (
        <Menu
            home={home}
            menuEntries={menuEntries}
            isMenuOpen={home.isMenuOpen}
            tokenLifeSecLeft={tokenLifeSecLeft}
            menuOpenCloseCb={({ isMenuOpen, menuEntry }: { isMenuOpen: boolean; menuEntry: IMenuEntry }) =>
                menuOpenCloseCb({ isMenuOpen, menuEntry })
            }
            translate={translate}
        />
    );
}

export default Header;
