import { round } from 'lodash';

import ConstantsHelper from './ConstantsHelper';
import { sendAmplitudeEvent } from './amplitude';
import DateTimeHelper from './DateTimeHelper';

import { IControl, IEdNote, IPatient, ITranslator } from '../types';
import { InsulinUsageUsageFactors, Patient, PatternType } from '../model/models';

export default class UtilityHelper {
    public static RoundNumber = (inVal: number, decimalCnt = 0): number =>
        isNaN(inVal) ? inVal : round(inVal, decimalCnt);

    public static RoundNumberAsString = (inVal: number, decimalCnt: number): string => {
        const outVal = UtilityHelper.RoundNumber(inVal, decimalCnt);

        if (outVal === 0 || isNaN(outVal)) {
            return '';
        } else {
            const flds = `${outVal}.`.toString().split('.');
            return decimalCnt === 0 ? flds[0] : `${flds[0]}.${flds[1].padEnd(decimalCnt, '0').substr(0, decimalCnt)}`;
        }
    };

    public static GetPercentage = (left: number, right: number, decimalCnt = 0): number =>
        right === 0 ? 0 : UtilityHelper.RoundNumber((left / right) * 100, decimalCnt);

    public static GetDiffInPercentage = (newVal: number, oldVal: number, decimalCnt = 0): number =>
        UtilityHelper.GetPercentage(newVal - oldVal, oldVal, decimalCnt);

    public static InsertIfNonExistent<T>(ary: T[], value: T) {
        if (!ary?.find((e) => e === value)) {
            ary.push(value);
        }
    }

    private static getNonNullVals = <T>(allVal: T[]): T[] =>
        allVal?.filter((e) => !UtilityHelper.IsNull(e)).map((e) => e);

    public static GetMinVal = <T>(allVal: T[]): T => {
        const allValNonNull = UtilityHelper.getNonNullVals(allVal);

        return allValNonNull?.length > 0 ? allValNonNull.reduce((v1, v2) => (v1 > v2 ? v2 : v1)) : null;
    };

    public static GetMaxVal = <T>(allVal: T[]): T => {
        const allValNonNull = UtilityHelper.getNonNullVals(allVal);

        return allValNonNull?.length > 0 ? allValNonNull.reduce((v1, v2) => (v1 > v2 ? v1 : v2)) : null;
    };

    public static GetMinTime = (dateTimeVals: string[]): string => {
        const hash = new Map();

        dateTimeVals.forEach((dt) => hash.set(dt.split('T')[1], dt));

        return hash.get(UtilityHelper.GetMinVal(Array.from(hash.keys())));
    };

    public static GetMaxTime = (dateTimeVals: string[]): string => {
        const hash = new Map();

        dateTimeVals.forEach((dt) => hash.set(dt.split('T')[1], dt));

        return hash.get(UtilityHelper.GetMaxVal(Array.from(hash.keys())));
    };

    public static CheckForRangeOverlap = (begA: number, endA: number, begB: number, endB: number): boolean =>
        begA <= endB && endA >= begB;

    private static readingsZapMilliseconds = <T>(arrayOfdictionaries: T[], key1: string, key2?: string): T[] => {
        arrayOfdictionaries?.forEach((dictEntry) => {
            const keys = [key1];

            if (key2) {
                keys.push(key2);
            }

            keys.forEach((key) => (dictEntry[key] = DateTimeHelper.ZapMilliseconds(dictEntry[key])));
        });

        return arrayOfdictionaries;
    };

    public static ReadingsSortAndDedupe = <T>(
        zapMilliseconds: boolean,
        arrayOfDicts: T[],
        key1: string,
        key2?: string
    ): T[] => {
        const arrayOfDictsLocal = zapMilliseconds
            ? UtilityHelper.readingsZapMilliseconds(arrayOfDicts, key1, key2)
            : arrayOfDicts;

        return [...new Map(arrayOfDictsLocal?.map((dictEntry) => [dictEntry[key1], dictEntry])).values()].sort(
            (dictEntry1, dictEntry2) => (dictEntry1[key1] < dictEntry2[key1] ? -1 : 1)
        );
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/btoa
    public static CryptoEncrypt = (plainText: string): string => btoa(plainText ?? '');

    public static CryptoDecrypt = (cipherText: string, defVal?: string): string =>
        UtilityHelper.IsNull(cipherText) ? defVal : atob(cipherText);

    private static makeKey = (key: string): string => `UniqueKey__${key}`;

    public static SettingGet = (key: string): string | undefined => {
        const value = window.localStorage.getItem(UtilityHelper.makeKey(key));

        return `${value}`.trim().replace('null', '').replace('undefined', '').length > 0 ? value : undefined;
    };

    public static SettingSet = (key: string, value: string) =>
        window.localStorage.setItem(UtilityHelper.makeKey(key), value ?? '');

    public static IsNull = <T>(input: T): boolean => input === null || input === undefined;

    public static IsFunction = (fnToCheck: any): boolean =>
        fnToCheck && {}.toString.call(fnToCheck) === '[object Function]';

    public static IsNumeric = <T>(input: T): boolean => !UtilityHelper.IsNull(input) && !isNaN(Number(input));

    public static ToNumeric = <T>(input: T): number => (UtilityHelper.IsNumeric(input) ? Number(input) : 0);

    public static generateLoginLink = (insuletIDURL: string, oktaApp: string, returnUrl: string) => {
        return `${insuletIDURL}/login?app=${oktaApp}&ReturnURL=${encodeURIComponent(returnUrl)}`;
    };
    /**
     * Redirects to login url and sends amplitude event about being redirected
     * @param loginURL
     */
    public static redirectToLogin = (loginURL: string) =>
        sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.REDIRECT_TO_LOGIN, {
            redirectPage: loginURL,
        })?.finally(() => window.location.assign(loginURL));
    public static ReportDaysAvailableGet = (patient: IPatient, defVal = '0'): number =>
        UtilityHelper.ToNumeric(
            UtilityHelper.CryptoDecrypt(patient?.reportDaysAvailableString ?? UtilityHelper.CryptoEncrypt(defVal))
        );

    public static GetEdNotes = (
        translate: ITranslator,
        patternType?: PatternType.PatternTypeEnum,
        insulinUsageUsageFactors?: InsulinUsageUsageFactors
    ): IEdNote[] => {
        let edNotes: IEdNote[] = [];

        switch (patternType) {
            case PatternType.PatternTypeEnum.High:
                edNotes = ConstantsHelper.EducationalNotesHigh;
                break;

            case PatternType.PatternTypeEnum.Low:
                edNotes = ConstantsHelper.EducationalNotesLow;
                break;

            default:
                edNotes = ConstantsHelper.EducationalNotesInsulinInsights.filter(
                    (e) =>
                        !(
                            (e.bolusRatioGoal && !insulinUsageUsageFactors?.bolusRatioGoal) ||
                            (e.bolusSettings && !insulinUsageUsageFactors?.bolusSettings)
                        )
                );
                break;
        }

        return edNotes.map((e) => ({
            ...e,
            text: translate(e.text, {
                name: translate(ConstantsHelper.NameTag),
                nameAlt: translate(ConstantsHelper.NameTagAlt),
                hyperHypo: translate(ConstantsHelper.CaptionHyperHypo),
            }),
        }));
    };

    public static MakeHttpCallKey = (callName: string, callParams?: string[]): string => {
        const params: string = callParams
            ?.filter((param) => !UtilityHelper.IsNull(param))
            ?.map((param) => `${param}`)
            ?.join('|');

        return `${callName}:${params}`;
    };

    public static IsHttpCallAllowed = (control: IControl, callKey: string): boolean => {
        if (control?.activeHttpCalls && callKey && control?.activeHttpCalls[callKey]) {
            return false;
        }

        return true;
    };

    public static GetFullName = (firstNameEncrypted: string, lastNameEncrypted: string): string => {
        if (firstNameEncrypted || lastNameEncrypted) {
            const firstName = UtilityHelper.CryptoDecrypt(firstNameEncrypted, '-');
            const lastName = UtilityHelper.CryptoDecrypt(lastNameEncrypted, '-');

            return `${firstName ?? '?'} ${lastName ?? '?'}`.trim();
        }

        return '';
    };

    public static GetDeviceClassName = (
        translate: ITranslator,
        deviceClassEnumValue: Patient.DeviceClassEnum
    ): string => {
        switch (deviceClassEnumValue) {
            case 'dash':
                return translate('constants.deviceClass.dash');
            case 'eros':
                return translate('constants.deviceClass.eros');
            case 'omnipod5':
                return translate('constants.deviceClass.omnipod5');
            default:
                return translate('constants.deviceClass.unknown');
        }
    };
}
