import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import clsx from 'clsx';

import DateTimeHelper from '../../helpers/DateTimeHelper';
import styleGeneral from '../../styles/general.module.scss';
import iconCalendar from '../../assets/images/icon-calendar.png';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import SystemHelper from '../../helpers/SystemHelper';
import { ICallback, IControl, IHome, ITranslator } from '../../types';

function WeekPicker({
    home,
    cbClickFn,
    translate,
}: {
    home: IHome;
    cbClickFn: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();
    const [title, setTitle] = useState<string>();
    const getGotoCaption = (suffix: string) => (suffix?.length > 0 ? `${translate('goTo')} "${suffix}"` : suffix);
    const setCalendarRange = (endDateIso: string) => {
        const { beg, end } = DateTimeHelper.GetDateRangeDatesOnly(endDateIso);

        setStartDate(beg);
        setEndDate(end);

        SystemHelper.AppInsightsInfo(home, 'Dashboard', 'Debug', `endDate=${endDate}`);
    };
    const setCalendarDate = (endDateIso: string, calendarEndDate: Date = null) => {
        setCalendarRange(endDateIso);
        processDate([calendarEndDate ?? new Date(endDateIso), null]);
    };
    const datePickerRef = useRef<DatePicker>();
    const processDate = (dates?: [Date, Date]) => {
        const dateStr = dates[0].toISOString();

        SystemHelper.AppInsightsInfo(home, 'Dashboard', 'Debug', `Date=${dates[0]}`);
        SystemHelper.AppInsightsInfo(home, 'Dashboard', 'Debug', `calendarOpen=${calendarOpen}`);

        cbClickFn(DateTimeHelper.GetIsoWeekRange(DateTimeHelper.GetDateNearestPastSaturday(dateStr, 7), 0));
    };
    const dateRangePrev = DateTimeHelper.GetIsoWeekRange(home.dashboardEnd, -7);
    const dateRangeNext = DateTimeHelper.GetIsoWeekRange(home.dashboardEnd, +7);

    useEffect(() => {
        setCalendarRange(home.dashboardEnd);
        setMaxDate(new Date(DateTimeHelper.GetDateNearestPastSaturday(null)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.dashboardEnd]);
    useEffect(() => {
        const translateDate = getGotoCaption(DateTimeHelper.FormatDateRange(dateRangeNext.end));
        setTitle(translateDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRangeNext.end]);

    return (
        <div className={styleGeneral.main}>
            <div className={styleGeneral.rangeSelector}>
                <div className={clsx(styleGeneral.dateSelector, control.testMode && styleGeneral.testModeOn)}>
                    <Tooltip title={`${translate('goTo')} "${DateTimeHelper.FormatDateRange(dateRangePrev.end)}"`}>
                        <Button
                            variant="primary"
                            onClick={() => setCalendarDate(dateRangePrev.end)}
                            className={styleGeneral.button}
                        >
                            <FontAwesomeIcon className={styleGeneral.left} icon={faChevronLeft} />
                        </Button>
                    </Tooltip>
                    <Tooltip title={title}>
                        <Button
                            variant="primary"
                            onClick={() => setCalendarDate(dateRangeNext.end)}
                            disabled={!dateRangeNext.end}
                            className={styleGeneral.button}
                        >
                            <FontAwesomeIcon className={styleGeneral.right} icon={faChevronRight} />
                        </Button>
                    </Tooltip>
                </div>
                <div className={clsx(styleGeneral.weekPicker, control.testMode && styleGeneral.testModeOn)}>
                    <Tooltip title={`${calendarOpen ? 'Hide' : 'Show'} calendar`}>
                        <img
                            src={iconCalendar}
                            className={clsx(styleGeneral.icon, calendarOpen ? styleGeneral.pending : false)}
                            alt="calendar"
                            onClick={(e) => {
                                e.preventDefault();
                                setCalendarOpen(!calendarOpen);
                            }}
                        />
                    </Tooltip>
                </div>
                <div className={styleGeneral.datePicker} title={translate('selectWeekToView')}>
                    {calendarOpen && (
                        <DatePicker
                            calendarClassName={styleGeneral.active}
                            ref={datePickerRef}
                            adjustDateOnChange={true}
                            todayButton={translate(ConstantsHelper.CaptionToday)}
                            dateFormat={'MMM d, yyyy'}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoFocus
                            className={styleGeneral.dateInput}
                            selected={endDate}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={maxDate}
                            selectsRange={true}
                            onChangeRaw={(event: React.FocusEvent<HTMLInputElement>) => {
                                if (event?.target?.innerText === translate(ConstantsHelper.CaptionToday)) {
                                    setCalendarDate(
                                        DateTimeHelper.GetDateNearestPastSaturday(maxDate.toISOString(), 6),
                                        maxDate
                                    );
                                }
                            }}
                            onChange={(dates: [Date, Date]) => processDate(dates)}
                            onCalendarClose={() => setCalendarOpen(false)}
                            onCalendarOpen={() => setCalendarOpen(true)}
                            inline
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default WeekPicker;
