import { PayloadAction } from '@reduxjs/toolkit';

import { homeInitialState } from './home.initialState';

import { IAuthentication, IControl, IBrowserInfo, IError, IHome, IPatient } from '../../types';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import ScreenHelper from '../../helpers/ScreenHelper';
import UiHelper from '../../helpers/UiHelper';
import UtilityHelper from '../../helpers/UtilityHelper';
import { Patient } from '../../model/patient';

const sessionRestoreBoolean = (stringValue: string, defVal = '1'): boolean => (stringValue ?? defVal) === '1';
const sessionRestore = (state: IHome, key: string) => {
    const value = UtilityHelper.SettingGet(key);
    const panelPositionValue = UtilityHelper.IsNumeric(value) ? Number(value) : 2;
    const testValue = state.control.testModeAllowed && sessionRestoreBoolean(value, '0');

    switch (key) {
        case ConstantsHelper.IntersessionKeys.calendarDate:
            state.calendarDate = value;
            break;
        case ConstantsHelper.IntersessionKeys.contributingEventBeg:
            state.contributingEventBeg = value;
            break;
        case ConstantsHelper.IntersessionKeys.contributingEventEnd:
            state.contributingEventEnd = value;
            break;
        case ConstantsHelper.IntersessionKeys.contributingEventId:
            state.contributingEventId = value;
            break;
        case ConstantsHelper.IntersessionKeys.dashboardHasReferrer:
            state.dashboardHasReferrer = sessionRestoreBoolean(value);
            break;
        case ConstantsHelper.IntersessionKeys.dashboardBeg:
            state.dashboardBeg = value;
            break;
        case ConstantsHelper.IntersessionKeys.dashboardEnd:
            state.dashboardEnd = value;
            break;
        case ConstantsHelper.IntersessionKeys.idTag:
            if (value?.length > 0) {
                state.idTag = value;
            }
            break;
        case ConstantsHelper.IntersessionKeys.insightId:
            state.insightId = value;
            break;
        case ConstantsHelper.IntersessionKeys.testCalendar:
            state.control.testCalendar = state.control.testModeAllowed && sessionRestoreBoolean(value);
            break;
        case ConstantsHelper.IntersessionKeys.testMode:
            state.control.testMode = testValue;
            break;
        case ConstantsHelper.IntersessionKeys.testFuture:
            state.control.testFuture = testValue;
            break;
        case ConstantsHelper.IntersessionKeys.testGenData:
            state.control.testGenData = testValue;
            break;
        case ConstantsHelper.IntersessionKeys.testKeepOffboard:
            state.control.testKeepOffboard = testValue;
            break;
        case ConstantsHelper.IntersessionKeys.testKeepOnboard:
            state.control.testKeepOnboard = testValue;
            break;
        case ConstantsHelper.IntersessionKeys.testTips:
            state.control.testTips = testValue;
            break;
        case ConstantsHelper.IntersessionKeys.showLegend:
            state.control.showLegend = UtilityHelper.IsNull(value) || sessionRestoreBoolean(value);
            break;
        case ConstantsHelper.IntersessionKeys.controlPanelExpanded:
            state.control.panelExpanded = UtilityHelper.IsNull(value) || sessionRestoreBoolean(value);
            break;
        case ConstantsHelper.IntersessionKeys.controlPanelTop:
            state.control.panelPos.top = panelPositionValue;
            break;
        case ConstantsHelper.IntersessionKeys.controlPanelLeft:
            state.control.panelPos.left = panelPositionValue;
            break;
        case ConstantsHelper.IntersessionKeys.signOutCanProceed:
            UtilityHelper.SettingSet(key, '0'); // Resets sign out flag
            break;
    }
};
const sessionSaveBoolean = (booleanValue: boolean): string => (booleanValue ? '1' : '0');
const sessionSave = (state: IHome, key: string) => {
    let value: string = undefined;

    switch (key) {
        case ConstantsHelper.IntersessionKeys.calendarDate:
            value = state.calendarDate;
            break;
        case ConstantsHelper.IntersessionKeys.contributingEventBeg:
            value = state.contributingEventBeg;
            break;
        case ConstantsHelper.IntersessionKeys.contributingEventEnd:
            value = state.contributingEventEnd;
            break;
        case ConstantsHelper.IntersessionKeys.contributingEventId:
            value = state.contributingEventId;
            break;
        case ConstantsHelper.IntersessionKeys.dashboardHasReferrer:
            value = sessionSaveBoolean(state.dashboardHasReferrer);
            break;
        case ConstantsHelper.IntersessionKeys.dashboardBeg:
            value = state.dashboardBeg;
            break;
        case ConstantsHelper.IntersessionKeys.dashboardEnd:
            value = state.dashboardEnd;
            break;
        case ConstantsHelper.IntersessionKeys.idTag:
            value = state.idTag;
            break;
        case ConstantsHelper.IntersessionKeys.insightId:
            value = state.insightId;
            break;
        case ConstantsHelper.IntersessionKeys.testCalendar:
            value = sessionSaveBoolean(state.control.testCalendar);
            break;
        case ConstantsHelper.IntersessionKeys.testMode:
            value = sessionSaveBoolean(state.control.testMode);
            break;
        case ConstantsHelper.IntersessionKeys.testFuture:
            value = sessionSaveBoolean(state.control.testFuture);
            break;
        case ConstantsHelper.IntersessionKeys.testGenData:
            value = sessionSaveBoolean(state.control.testGenData);
            break;
        case ConstantsHelper.IntersessionKeys.testKeepOffboard:
            value = sessionSaveBoolean(state.control.testKeepOffboard);
            break;
        case ConstantsHelper.IntersessionKeys.testKeepOnboard:
            value = sessionSaveBoolean(state.control.testKeepOnboard);
            break;
        case ConstantsHelper.IntersessionKeys.testTips:
            value = sessionSaveBoolean(state.control.testTips);
            break;
        case ConstantsHelper.IntersessionKeys.showLegend:
            value = sessionSaveBoolean(state.control.showLegend);
            break;
        case ConstantsHelper.IntersessionKeys.controlPanelExpanded:
            value = sessionSaveBoolean(state.control.panelExpanded);
            break;
        case ConstantsHelper.IntersessionKeys.controlPanelTop:
            value = state.control.panelPos.top?.toString();
            break;
        case ConstantsHelper.IntersessionKeys.controlPanelLeft:
            value = state.control.panelPos.left?.toString();
            break;
        case ConstantsHelper.IntersessionKeys.signOutCanProceed:
            value = '1';
            break;
    }

    UtilityHelper.SettingSet(key, value);
};

export const homeReducer = {
    intersessionDataRestore: {
        reducer: (
            state: IHome,
            action: PayloadAction<{
                browserInfo: IBrowserInfo;
                isProd: boolean;
                testModeAllowed: boolean;
                pauseItEnabled: boolean;
            }>
        ) => {
            state.control.browserInfo = action.payload.browserInfo;
            state.control.isProd = action.payload.isProd;
            state.control.testModeAllowed = action.payload.testModeAllowed;
            state.control.pauseItEnabled = action.payload.pauseItEnabled;

            if (state.control.testMode) {
                state.control.testPauseItEnabledOverride = action.payload.pauseItEnabled;
            }

            Object.keys(ConstantsHelper.IntersessionKeys).forEach((key) => sessionRestore(state, key));

            if (state.dashboardHasReferrer) {
                // Clear these since page was reloaded and needs to go to the latest week
                state.dashboardBeg = undefined;
                state.dashboardEnd = undefined;
                state.dashboardHasReferrer = false;
            }

            state.allLoaded = true;
            state.control.reloadInProgress = false;
        },
        prepare: ({
            browserInfo,
            isProd,
            testModeAllowed,
            pauseItEnabled,
        }: {
            browserInfo: IBrowserInfo;
            isProd: boolean;
            testModeAllowed: boolean;
            pauseItEnabled: boolean;
        }): any => ({
            payload: {
                browserInfo,
                isProd,
                testModeAllowed,
                pauseItEnabled,
            },
        }),
    },
    intersessionDataSave: {
        reducer: (state: IHome, action: PayloadAction<{ keys: string[] }>) => {
            Object.keys(ConstantsHelper.IntersessionKeys).forEach((key) => {
                if (action.payload.keys.find((e) => e === key)) {
                    sessionSave(state, key);
                }
            });
        },
        prepare: ({ keys }: { keys: string[] }) => ({
            payload: { keys },
        }),
    },
    anI18NextLib: {
        reducer: (state: IHome, action: PayloadAction<{ anI18Nextlib: any }>) => {
            state.anI18Nextlib = action.payload.anI18Nextlib;
        },
        prepare: ({ anI18Nextlib }: { anI18Nextlib: any }) => ({
            payload: {
                anI18Nextlib,
            },
        }),
    },
    endpointUrlPrefix: {
        reducer: (
            state: IHome,
            action: PayloadAction<{
                endpointPwdUrlPrefix: string;
                endpointHcpUrlPrefix: string;
                endpointPdfUrlPrefix: string;
            }>
        ) => {
            state.endpointPwdUrlPrefix = action.payload.endpointPwdUrlPrefix;
            state.endpointHcpUrlPrefix = action.payload.endpointHcpUrlPrefix;
            state.endpointPdfUrlPrefix = action.payload.endpointPdfUrlPrefix;
        },
        prepare: ({
            endpointPwdUrlPrefix,
            endpointHcpUrlPrefix,
            endpointPdfUrlPrefix,
        }: {
            endpointPwdUrlPrefix: string;
            endpointHcpUrlPrefix: string;
            endpointPdfUrlPrefix: string;
        }) => ({
            payload: {
                endpointPwdUrlPrefix,
                endpointHcpUrlPrefix,
                endpointPdfUrlPrefix,
            },
        }),
    },
    authentication: {
        reducer: (state: IHome, action: PayloadAction<{ authentication: Partial<IAuthentication> }>) => {
            state.authentication = {
                ...state.authentication,
                ...action.payload.authentication,
            };

            if (!state.authentication.oktaData.isUserDefined) {
                state.allLoaded = false;
                state.anchorInsight = undefined;
                state.anchorEvent = undefined;
                state.anchorAllReports = undefined;
                state.anchorLoyaltyCongrats = undefined;
                state.anchorLoyaltyInsulin = undefined;
                state.allReportsRowCnt = 0;
                state.authentication = {
                    ...state.authentication,
                };
                state.control.reloadInProgress = false;
                state.currentDashboard = {};
                state.cachedInsights = {};
                state.currentReadingCombo = {};
                state.currentSummary = {};
                state.currentSummaryGlucoseTrend = {};
                state.carouselIndex = 0;
                state.contributingEventId = undefined;
                state.contributingEventBeg = undefined;
                state.contributingEventEnd = undefined;
                state.dashboardBeg = undefined;
                state.dashboardEnd = undefined;
                state.insightId = undefined;
                state.patient = {
                    reportDaysAvailableString: undefined,
                    isEnrolled: false,
                    isUnenrolled: false,
                    isOnboarded: false,
                    receivedData: false,
                };
                state.errors = {};
            }
        },
        prepare: ({ authentication }: { authentication: Partial<IAuthentication> }) => ({
            payload: {
                authentication,
            },
        }),
    },
    control: {
        reducer: (state: IHome, action: PayloadAction<{ control: Partial<IControl> }>) => {
            if (
                !UtilityHelper.IsNull(action.payload.control?.readingsInfo?.fillGaps) &&
                state.control.readingsInfo?.fillGaps !== action.payload.control?.readingsInfo?.fillGaps
            ) {
                state.currentReadingCombo.readingsCombo = action.payload.control?.readingsInfo?.fillGaps
                    ? state.currentReadingCombo.readingsComboNoGaps
                    : state.currentReadingCombo.readingsComboGaps;
            }

            state.control = {
                ...state.control,
                ...action.payload.control,
                browserInfo: {
                    ...state.control.browserInfo,
                    ...action.payload.control?.browserInfo,
                },
                activeHttpCalls: {
                    ...state.control.activeHttpCalls,
                    ...action.payload.control?.activeHttpCalls,
                },
                panelPos: {
                    ...state.control.panelPos,
                    ...action.payload.control?.panelPos,
                },
                readingsInfo: {
                    ...state.control.readingsInfo,
                    ...action.payload.control?.readingsInfo,
                },
                windowSize: {
                    ...state.control.windowSize,
                    ...action.payload.control?.windowSize,
                },
            };

            if (state.control.testMode) {
                state.control.pauseItEnabled = state.control.testPauseItEnabledOverride;

                if (state.control.testPauseItEnabledOverride) {
                    state.patient.eulaAccepted = false;
                    state.patient.confidentialityAgreementAccepted = false;
                    state.patient.smsNumberStatus = undefined;
                    state.patient.deviceClass = Patient.DeviceClassEnum.Omnipod5;
                }
            }

            if (
                !UtilityHelper.IsNull(action.payload.control?.testForcePending) ||
                !UtilityHelper.IsNull(action.payload.control?.testForceRevoked)
            ) {
                state.patient.isEnrolled = undefined;
                state.patient.isUnenrolled = undefined;
                state.patient.isOnboarded = undefined;
                if (action.payload.control?.testForceRevoked) {
                    state.control.testForcePending = false;
                } else if (action.payload.control?.testForcePending) {
                    state.control.testForceRevoked = false;
                }
            }

            const controlPanelPosNew = ScreenHelper.GetControlPanelPos(
                state.control.windowSize,
                state.control.panelPos
            );

            if (
                state.control.panelPos.left !== controlPanelPosNew.left ||
                state.control.panelPos.top !== controlPanelPosNew.top
            ) {
                state.control.panelPos = { ...controlPanelPosNew };
            }
        },
        prepare: ({ control }: { control: Partial<IControl> }) => ({
            payload: {
                control,
            },
        }),
    },
    error: {
        reducer: (state: IHome, action: PayloadAction<{ key: string; error: IError }>) => {
            if (!action.payload.key) {
                state.errors = homeInitialState.errors;
            } else if (!action.payload.error) {
                if (action.payload.key === ConstantsHelper.httpCallAndErrorKeys.general) {
                    state.errors[action.payload.key] = homeInitialState.errors[action.payload.key];
                } else {
                    delete state.errors[action.payload.key];
                }
            } else {
                const errorFinal = UiHelper.CreateErrorMessage({
                    ...action.payload.error,
                });

                if (isNaN(errorFinal.level)) {
                    errorFinal.level = errorFinal.message
                        ? ConstantsHelper.ErrorLevels.info
                        : ConstantsHelper.ErrorLevels.clear;
                }

                state.errors[action.payload.key] = errorFinal;
            }
        },
        prepare: ({ key, error }: { key: string; error: IError }) => ({
            payload: {
                key,
                error,
            },
        }),
    },
    loading: {
        reducer: (state: IHome, action: PayloadAction<{ loadingDir: number }>) => {
            state.loadingSemaphore = Math.max(0, state.loadingSemaphore + action.payload.loadingDir);
        },
        prepare: ({ loadingDir }: { loadingDir: number }) => ({
            payload: {
                loadingDir,
            },
        }),
    },
    dashboardMetaData: {
        reducer: (
            state: IHome,
            action: PayloadAction<{
                dashboardBeg: string;
                dashboardEnd: string;
                dashboardHasReferrer?: boolean;
            }>
        ) => {
            state.dashboardBeg = action.payload.dashboardBeg;
            state.dashboardEnd = action.payload.dashboardEnd;
            state.dashboardHasReferrer = action.payload.dashboardHasReferrer;
            state.currentSummary = {};
            state.currentSummaryGlucoseTrend = {};
            state.currentDashboard = {};
            state.currentReadingCombo = {};
            state.cachedInsights = {};
        },
        prepare: ({
            dashboardBeg,
            dashboardEnd,
            dashboardHasReferrer,
        }: {
            dashboardBeg: string;
            dashboardEnd: string;
            dashboardHasReferrer?: boolean;
        }) => ({
            payload: {
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer,
            },
        }),
    },
    isMenuOpen: {
        reducer: (state: IHome, action: PayloadAction<{ isMenuOpen: boolean }>) => {
            state.isMenuOpen = action.payload.isMenuOpen;
        },
        prepare: ({ isMenuOpen }: { isMenuOpen: boolean }) => ({
            payload: {
                isMenuOpen,
            },
        }),
    },
    insightId: {
        reducer: (state: IHome, action: PayloadAction<{ insightId: string }>) => {
            state.insightId = action.payload.insightId;
        },
        prepare: ({ insightId }: { insightId: string }) => ({
            payload: {
                insightId,
            },
        }),
    },
    anchorLoyaltyCongrats: {
        reducer: (state: IHome, action: PayloadAction<{ anchorLoyaltyCongrats: string }>) => {
            state.anchorLoyaltyCongrats = action.payload.anchorLoyaltyCongrats;
            state.anchorLoyaltyInsulin = undefined;
            state.anchorInsight = undefined;
        },
        prepare: ({ anchorLoyaltyCongrats }: { anchorLoyaltyCongrats: string }) => ({
            payload: {
                anchorLoyaltyCongrats,
            },
        }),
    },
    anchorLoyaltyInsulin: {
        reducer: (state: IHome, action: PayloadAction<{ anchorLoyaltyInsulin: string }>) => {
            state.anchorLoyaltyCongrats = undefined;
            state.anchorLoyaltyInsulin = action.payload.anchorLoyaltyInsulin;
            state.anchorInsight = undefined;
        },
        prepare: ({ anchorLoyaltyInsulin }: { anchorLoyaltyInsulin: string }) => ({
            payload: {
                anchorLoyaltyInsulin,
            },
        }),
    },
    anchorInsight: {
        reducer: (state: IHome, action: PayloadAction<{ anchorInsight: string }>) => {
            state.anchorLoyaltyCongrats = undefined;
            state.anchorLoyaltyInsulin = undefined;
            state.anchorInsight = action.payload.anchorInsight;
        },
        prepare: ({ anchorInsight }: { anchorInsight: string }) => ({
            payload: {
                anchorInsight,
            },
        }),
    },
    anchorEvent: {
        reducer: (state: IHome, action: PayloadAction<{ anchorEvent: string }>) => {
            state.anchorEvent = action.payload.anchorEvent;
        },
        prepare: ({ anchorEvent }: { anchorEvent: string }) => ({
            payload: {
                anchorEvent,
            },
        }),
    },
    anchorAllReports: {
        reducer: (state: IHome, action: PayloadAction<{ anchorAllReports: string }>) => {
            state.anchorAllReports = action.payload.anchorAllReports;
        },
        prepare: ({ anchorAllReports }: { anchorAllReports: string }) => ({
            payload: {
                anchorAllReports,
            },
        }),
    },
    allReportsRowCnt: {
        reducer: (state: IHome, action: PayloadAction<{ allReportsRowCnt: number }>) => {
            state.allReportsRowCnt = action.payload.allReportsRowCnt;
        },
        prepare: ({ allReportsRowCnt }: { allReportsRowCnt: number }) => ({
            payload: {
                allReportsRowCnt,
            },
        }),
    },
    contributingEventMetaData: {
        reducer: (
            state: IHome,
            action: PayloadAction<{
                contributingEventId: string;
                contributingEventBeg: string;
                contributingEventEnd: string;
            }>
        ) => {
            state.contributingEventId = action.payload.contributingEventId;
            state.contributingEventBeg = action.payload.contributingEventBeg;
            state.contributingEventEnd = action.payload.contributingEventEnd;
            state.currentReadingCombo = {};
        },
        prepare: ({
            contributingEventId,
            contributingEventBeg,
            contributingEventEnd,
        }: {
            contributingEventId: string;
            contributingEventBeg: string;
            contributingEventEnd: string;
        }) => ({
            payload: {
                contributingEventId,
                contributingEventBeg,
                contributingEventEnd,
            },
        }),
    },
    carouselSetIndex: {
        reducer: (state: IHome, action: PayloadAction<{ index: number }>) => {
            state.carouselIndex = action.payload.index;
        },
        prepare: ({ index }: { index: number }) => ({
            payload: {
                index,
            },
        }),
    },
    updatePatient: {
        reducer: (state: IHome, action: PayloadAction<{ patient: Partial<IPatient> }>) => {
            state.patient = {
                ...state.patient,
                ...action.payload.patient,
            };
        },
        prepare: ({ patient }: { patient: Partial<IPatient> }) => ({
            payload: {
                patient,
            },
        }),
    },
    pollProfile: {
        reducer: (state: IHome, action: PayloadAction<{ active: boolean }>) => {
            state.isPolling = action.payload.active;
        },
        prepare: ({ active }: { active: boolean }) => ({
            payload: {
                active,
            },
        }),
    },
};
