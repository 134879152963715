import styleGuide from '../../styles/styleGuide.module.scss';

const classByValueRange = (value?: number) => {
    if (value === undefined) {
        // this satisfies both null and undefined use cases.
        return styleGuide.normal;
    }
    return value >= 0 ? styleGuide.high : styleGuide.low;
};

export default classByValueRange;
