import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Patient } from 'src/model/patient';

import UiHelper from '../../../helpers/UiHelper';
import { ICallback, IHome, ITranslator } from '../../../types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import SmsNumberInput from '../SmsNumberInput';

function ContentWrapperConfirmSmsNumber({
    smsNumber,
    home,
    closeCb,
    resendCb,
    confirmedCb,
    translate,
}: {
    smsNumber: string;
    home: IHome;
    closeCb: ICallback<void>;
    resendCb: ICallback<void>;
    confirmedCb: ICallback<void>;
    translate: ITranslator;
}) {
    useEffect(() => {
        if (
            home.patient.smsNumberStatus === Patient.SmsNumberStatusEnum.Out ||
            home.patient.smsNumberStatus === Patient.SmsNumberStatusEnum.In
        ) {
            confirmedCb();
        }
    }, [home.patient.smsNumberStatus, confirmedCb]);

    const handleBackClick = () => {
        closeCb();
    };

    const handleResendClick = () => {
        resendCb();
    };

    return (
        <div
            className={UiHelper.GetClassNamesHeader(
                home.control?.isProd,
                home.control?.browserInfo?.supported,
                styleGeneral.body,
                styleGeneral.bodyAlt1,
                styleGeneral.bodyAlt2,
                styleGeneral.whiteBackground
            )}
        >
            <div className={clsx(styleGuide.verifySmsNumber)}>
                <div className={styleGuide.title}>{translate('confirmSmsNumber.header')}</div>
                <div className={styleGuide.paragraph}>{translate('confirmSmsNumber.subtitle')}</div>
                <SmsNumberInput value={smsNumber} disabled />
                <div className={styleGuide.paragraph}>
                    {translate('confirmSmsNumber.resend')}{' '}
                    <Link className={styleGeneral.link} onClick={handleResendClick} to="#">
                        {translate('confirmSmsNumber.resendLink')}
                    </Link>
                </div>
                <div className={styleGuide.paragraph}>{translate('confirmSmsNumber.backInstructions')}</div>
                <div className={styleGuide.buttons}>
                    <Link
                        data-testid="confirm-sms-number__back-button"
                        className={clsx(styleGuide.button, styleGuide.secondary)}
                        onClick={handleBackClick}
                        to="#"
                    >
                        {translate('confirmSmsNumber.back')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperConfirmSmsNumber;
