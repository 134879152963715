import moment from 'moment';

import ConstantsHelper from './ConstantsHelper';
import DateTimeHelper from './DateTimeHelper';
import UtilityHelper from './UtilityHelper';

import podActivating from '../assets/images/podActivating.svg';
import podActivated from '../assets/images/podActivated.svg';
import podDeactivated from '../assets/images/podDeactivated.svg';
import { DataItemModesPodStatuses, Mode, PatternType, Submode } from '../model/models';
import {
    IChartPayloadData,
    IChartPayload,
    IInsight,
    IChartEntryBloodGlucose,
    IContributingEvent,
    IReading,
    ICrossOvers,
    IRectangleSize,
    IIDataItemReadings,
    IBulletPoint,
    IBolusBase,
    ILegendEntry,
    ILegendSection,
} from '../types';

export default class ChartHelper {
    private static getCrossOvers = <T>(dataSeries: T[]): ICrossOvers => {
        const crossOvers: ICrossOvers = { daily: [], hourly: [] };

        if (dataSeries?.length > 0) {
            const timeRangeToh = DateTimeHelper.GenerateTimeRange(
                dataSeries[0]['x'],
                dataSeries[dataSeries.length - 1]['x'],
                0,
                0,
                3600,
                true
            );

            timeRangeToh.forEach((tohEntry, idx) => {
                if (dataSeries.find((dataEntry) => dataEntry['x'] === tohEntry)) {
                    crossOvers.hourly.push(tohEntry);
                }

                if (idx === 0 || (idx > 0 && tohEntry.split('T')[0] !== timeRangeToh[idx - 1].split('T')[0])) {
                    crossOvers.daily.push(tohEntry);
                }
            });
        }

        return crossOvers;
    };

    private static getAxisTicksX = (windowSize: IRectangleSize, crossOvers: ICrossOvers): number => {
        let incr = 1;

        if (crossOvers.hourly.length > 9) {
            let ticks = 4;

            if (windowSize.width > 800) {
                ticks = 12;
            } else if (windowSize.width > 400) {
                ticks = 8;
            }

            incr = Math.ceil(crossOvers.hourly.length / ticks);
        }

        return incr;
    };

    public static GetAxisTicksX = (windowSize: IRectangleSize, crossOvers: ICrossOvers): string[] => {
        const ticksX: string[] = [];

        if (windowSize?.width > 0 && crossOvers?.hourly?.length) {
            const incr = ChartHelper.getAxisTicksX(windowSize, crossOvers);
            let idxBeg = 1;
            let idxEnd = crossOvers.hourly.length - 1;

            if (incr === 1) {
                idxBeg = 0;
                idxEnd = crossOvers.hourly.length;
            }

            for (let idx = idxBeg; idx < idxEnd; idx += incr) {
                ticksX.push(crossOvers.hourly[idx]);
            }

            /* istanbul ignore next: if statement never reachable from tests since 'if' above does not allow crossOvers.hourly.length to be 0  */
            if (ticksX.length === 0) {
                ticksX.push(crossOvers.hourly[0]);

                if (crossOvers.hourly.length > 1) {
                    ticksX.push(crossOvers.hourly[crossOvers.hourly.length - 1]);
                }
            }
        }

        return ticksX;
    };

    private static getBloodGlucoseDataAttributesA = (contributingEvent: IContributingEvent, insight: IInsight) => {
        const payload: IChartPayloadData[] = [];
        const highEgvMgDlThreshold = insight?.highEgvMgDlThreshold ?? 0;
        const lowEgvMgDlThreshold = insight?.lowEgvMgDlThreshold ?? 0;
        const yMidPoint = lowEgvMgDlThreshold + Math.ceil((highEgvMgDlThreshold - lowEgvMgDlThreshold) / 2);
        const bolusLocation = 40 + yMidPoint;
        const entries: IChartEntryBloodGlucose[] = [];
        const entriesSmoothing: IChartEntryBloodGlucose[] = [];
        const xCutOffA: string = moment(contributingEvent.beg).utcOffset(0).format();
        const xCutOffB: string = moment(contributingEvent.end).utcOffset(0).format();
        const xMarkers: moment.Moment[] = [];

        return {
            payload,
            highEgvMgDlThreshold,
            lowEgvMgDlThreshold,
            yMidPoint,
            bolusLocation,
            entries,
            entriesSmoothing,
            xCutOffA,
            xCutOffB,
            xMarkers,
        };
    };

    private static getBloodGlucoseDataAttributesGetBoluses = (readingEntry: IReading) =>
        readingEntry.boluses?.map((bolusEntry) => {
            const boluscorrection = bolusEntry.bolusCorrection ? 0 : null;
            const bolusTotal = bolusEntry.bolusTotal ? 0 : null;
            return {
                bolusCorrection: bolusEntry.bolusCorrection ? Number(bolusEntry.bolusCorrection) : null,
                bolusMeal: bolusEntry.bolusMeal ? Number(bolusEntry.bolusMeal) : boluscorrection,
                bolusTotal: bolusEntry.bolusTotal ? Number(bolusEntry.bolusTotal) : bolusTotal,
                bolusTime: bolusEntry.bolusTime,
                bolusType: bolusEntry.bolusType,
                carbs: bolusEntry.carbs ? Number(bolusEntry.carbs) : null,
            } as IBolusBase;
        });

    private static getBloodGlucoseDataAttributesB = (
        readings: IReading[],
        entries: IChartEntryBloodGlucose[],
        highEgvMgDlThreshold: number,
        lowEgvMgDlThreshold: number,
        bolusLocation: number
    ) => {
        readings?.forEach((readingEntry) => {
            const xMoment = moment(readingEntry.time).utcOffset(0);
            const y = readingEntry.egv;
            const haveBolus = readingEntry.boluses?.length > 0;
            const yHigh = y > highEgvMgDlThreshold ? y : null;
            const yLow = y < lowEgvMgDlThreshold ? y : null;
            const boluses = ChartHelper.getBloodGlucoseDataAttributesGetBoluses(readingEntry);
            const newEntry: IChartEntryBloodGlucose = {
                boluses,
                y,
                yHigh,
                yInRange: yHigh === null && yLow === null ? y : null,
                yLow,
                yBolus: haveBolus ? bolusLocation : null,
                m: xMoment,
            };

            entries.push(newEntry);
        });
    };

    private static getBloodGlucoseDataAttributesC0 = (
        newEntry: IChartEntryBloodGlucose,
        entriesSmoothing: IChartEntryBloodGlucose[],
        highEgvMgDlThreshold: number,
        lowEgvMgDlThreshold: number,
        oldEntry: IChartEntryBloodGlucose,
        markerX: moment.Moment[]
    ) => {
        let entrySmoothing: IChartEntryBloodGlucose;

        // Cases B & C
        if (newEntry.yInRange) {
            // Case B
            entrySmoothing = {
                ...oldEntry,
                boluses: undefined,
                m: oldEntry.m.clone().add(500, 'milliseconds'),
                yLow: lowEgvMgDlThreshold - 0.01,
                yInRange: lowEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);
        } else if (newEntry.yHigh) {
            // Case C
            entrySmoothing = {
                ...oldEntry,
                boluses: undefined,
                m: oldEntry.m.clone().add(500, 'milliseconds'),
                yLow: lowEgvMgDlThreshold - 0.01,
                yInRange: lowEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);

            entrySmoothing = {
                ...oldEntry,
                boluses: undefined,
                m: newEntry.m.clone().add(-500, 'milliseconds'),
                yLow: null,
                yHigh: highEgvMgDlThreshold + 0.01,
                yInRange: highEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);
        }
    };

    private static getBloodGlucoseDataAttributesC1 = (
        newEntry: IChartEntryBloodGlucose,
        entriesSmoothing: IChartEntryBloodGlucose[],
        highEgvMgDlThreshold: number,
        lowEgvMgDlThreshold: number,
        oldEntry: IChartEntryBloodGlucose,
        markerX: moment.Moment[]
    ) => {
        let entrySmoothing: IChartEntryBloodGlucose;

        // Cases G & H
        if (newEntry.yLow) {
            // Case G
            entrySmoothing = {
                ...oldEntry,
                boluses: undefined,
                m: oldEntry.m.clone().add(500, 'milliseconds'),
                yHigh: highEgvMgDlThreshold + 0.01,
                yInRange: highEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);

            entrySmoothing = {
                ...oldEntry,
                boluses: undefined,
                m: newEntry.m.clone().add(-500, 'milliseconds'),
                yLow: lowEgvMgDlThreshold - 0.01,
                yInRange: lowEgvMgDlThreshold,
                yHigh: null,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);
        } else if (newEntry.yInRange) {
            // Case H
            entrySmoothing = {
                ...oldEntry,
                boluses: undefined,
                m: oldEntry.m.clone().add(500, 'milliseconds'),
                yHigh: highEgvMgDlThreshold + 0.01,
                yInRange: highEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);
        }
    };

    private static getBloodGlucoseDataAttributesC2 = (
        newEntry: IChartEntryBloodGlucose,
        entriesSmoothing: IChartEntryBloodGlucose[],
        highEgvMgDlThreshold: number,
        lowEgvMgDlThreshold: number,
        oldEntry: IChartEntryBloodGlucose,
        markerX: moment.Moment[]
    ) => {
        let entrySmoothing: IChartEntryBloodGlucose;

        // Cases D & F
        if (newEntry.yLow) {
            // Case D
            entrySmoothing = {
                ...newEntry,
                boluses: undefined,
                m: newEntry.m.clone().add(-500, 'milliseconds'),
                yLow: lowEgvMgDlThreshold - 0.01,
                yInRange: lowEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);
        } else if (newEntry.yHigh) {
            // Case F
            entrySmoothing = {
                ...newEntry,
                boluses: undefined,
                m: newEntry.m.clone().add(-500, 'milliseconds'),
                yHigh: highEgvMgDlThreshold + 0.01,
                yInRange: highEgvMgDlThreshold,
            };

            markerX.push(entrySmoothing.m);
            entriesSmoothing.push(entrySmoothing);
        }
    };

    private static getBloodGlucoseDataAttributesC = (
        entries: IChartEntryBloodGlucose[],
        entriesSmoothing: IChartEntryBloodGlucose[],
        xMarkers: moment.Moment[],
        highEgvMgDlThreshold: number,
        lowEgvMgDlThreshold: number
    ) => {
        let oldEntry: IChartEntryBloodGlucose = null;

        entries.forEach((newEntry) => {
            if (!UtilityHelper.IsNull(oldEntry)) {
                const markerX: moment.Moment[] = [];
                const oldIsNull = UtilityHelper.IsNull(oldEntry.y);
                const newIsNull = UtilityHelper.IsNull(newEntry.y);

                /*
                    Point Transition Map
                    BEFORE         AFTER
                    old    New     old    New
                    l i h  l i h   l i h  l i h  Changed
                    -----  -----   -----  -----  -------
                A)  1 0 0  1 0 0   1 0 0  1 0 0  N
                B)  1 0 0  0 1 0   1 1 0  0 1 0  Y
                C)  1 0 0  0 0 1   1 1 0  0 1 1  Y
    
                D)  0 1 0  1 0 0   0 1 0  1 1 0  Y
                E)  0 1 0  0 1 0   0 1 0  0 1 0  N
                F)  0 1 0  0 0 1   0 1 0  0 1 1  Y
    
                G)  0 0 1  1 0 0   0 1 1  1 1 0  Y
                H)  0 0 1  0 1 0   0 1 1  0 1 0  Y
                I)  0 0 1  0 0 1   0 0 1  0 0 1  N
                */
                if (!oldIsNull && newIsNull) {
                    markerX.push(oldEntry.m);
                } else if (oldIsNull && !newIsNull) {
                    markerX.push(newEntry.m);
                } else if (oldEntry.yLow) {
                    // Cases B & C
                    ChartHelper.getBloodGlucoseDataAttributesC0(
                        newEntry,
                        entriesSmoothing,
                        highEgvMgDlThreshold,
                        lowEgvMgDlThreshold,
                        oldEntry,
                        markerX
                    );
                } else if (oldEntry.yHigh) {
                    // Cases G & H
                    ChartHelper.getBloodGlucoseDataAttributesC1(
                        newEntry,
                        entriesSmoothing,
                        highEgvMgDlThreshold,
                        lowEgvMgDlThreshold,
                        oldEntry,
                        markerX
                    );
                } else if (oldEntry.yInRange) {
                    // Cases D & F
                    ChartHelper.getBloodGlucoseDataAttributesC2(
                        newEntry,
                        entriesSmoothing,
                        highEgvMgDlThreshold,
                        lowEgvMgDlThreshold,
                        oldEntry,
                        markerX
                    );
                }

                markerX.filter((m) => !xMarkers.find((x) => x === m)).forEach((m) => xMarkers.push(m));
            }

            oldEntry = newEntry;
        });
    };

    private static getBloodGlucoseDataAttributesD = (
        entries: IChartEntryBloodGlucose[],
        entriesSmoothing: IChartEntryBloodGlucose[],
        payload: IChartPayloadData[],
        insight: IInsight,
        yMidPoint: number,
        highEgvMgDlThreshold: number,
        lowEgvMgDlThreshold: number
    ) => {
        const entriesFinal: IChartEntryBloodGlucose[] = [...entries, ...entriesSmoothing]
            .sort((e1, e2) => (e1.m.isAfter(e2.m) ? 1 : -1))
            .map((e) => ({
                ...e,
                x: e.m.utcOffset(0).format(),
            }));

        payload.push(
            {
                id: ConstantsHelper.DataSeriesNormal,
                data: entriesFinal.map((e) => ({ x: e.x, y: e.yInRange } as IChartEntryBloodGlucose)),
            },
            {
                id: ConstantsHelper.DataSeriesLow,
                data: entriesFinal.map((e) => ({ x: e.x, y: e.yLow } as IChartEntryBloodGlucose)),
            },
            {
                id: ConstantsHelper.DataSeriesHigh,
                data: entriesFinal.map((e) => ({ x: e.x, y: e.yHigh } as IChartEntryBloodGlucose)),
            },
            {
                id: ConstantsHelper.DataSeriesBolus,
                data: entriesFinal.map(
                    (e) =>
                        ({
                            x: e.x,
                            y: e.yBolus,
                            boluses: e.boluses,
                        } as IChartEntryBloodGlucose)
                ),
            }
        );

        const egvMax = UtilityHelper.GetMaxVal([...entriesFinal.map((e) => e.yHigh)]) ?? highEgvMgDlThreshold;
        const egvMin = UtilityHelper.GetMinVal([...entriesFinal.map((e) => e.yLow)]) ?? lowEgvMgDlThreshold;
        const allYs = [egvMax, egvMin, lowEgvMgDlThreshold, highEgvMgDlThreshold];
        const yCutOffTypeLowValue =
            insight?.patternType === PatternType.PatternTypeEnum.Low ? lowEgvMgDlThreshold : yMidPoint;

        return { entriesFinal, egvMax, egvMin, allYs, yCutOffTypeLowValue };
    };

    public static GetBloodGlucoseDataAttributes = (
        tag: string,
        tag2: string,
        readings: IReading[],
        contributingEvent: IContributingEvent,
        insight: IInsight
    ): IChartPayload => {
        const {
            payload,
            highEgvMgDlThreshold,
            lowEgvMgDlThreshold,
            yMidPoint,
            bolusLocation,
            entries,
            entriesSmoothing,
            xCutOffA,
            xCutOffB,
            xMarkers,
        } = ChartHelper.getBloodGlucoseDataAttributesA(contributingEvent, insight);

        ChartHelper.getBloodGlucoseDataAttributesB(
            readings,
            entries,
            highEgvMgDlThreshold,
            lowEgvMgDlThreshold,
            bolusLocation
        );
        ChartHelper.getBloodGlucoseDataAttributesC(
            entries,
            entriesSmoothing,
            xMarkers,
            highEgvMgDlThreshold,
            lowEgvMgDlThreshold
        );

        const { entriesFinal, egvMax, egvMin, allYs, yCutOffTypeLowValue } = ChartHelper.getBloodGlucoseDataAttributesD(
            entries,
            entriesSmoothing,
            payload,
            insight,
            yMidPoint,
            highEgvMgDlThreshold,
            lowEgvMgDlThreshold
        );

        return {
            crossOvers: ChartHelper.getCrossOvers(entriesFinal),
            data: payload,
            egvMax,
            egvMin,
            tag,
            tag2,
            windowBeg: contributingEvent.windowBeg,
            windowEnd: contributingEvent.windowEnd,
            xCutOffA,
            xCutOffB,
            xMarkers: xMarkers.map((m) => DateTimeHelper.FormatTimeForChart(m.toISOString())),
            xMax: entriesFinal?.length ? entriesFinal[entriesFinal.length - 1].x : undefined,
            xMin: entriesFinal?.length ? entriesFinal[0].x : undefined,
            yCutOff:
                insight?.patternType === PatternType.PatternTypeEnum.High ? highEgvMgDlThreshold : yCutOffTypeLowValue,
            yMax: Math.max(ConstantsHelper.ReadingsGoodEgvMax, UtilityHelper.GetMaxVal(allYs)),
            yMin: Math.min(ConstantsHelper.ReadingsGoodEgvMin, UtilityHelper.GetMinVal(allYs)),
        };
    };

    private static getInsulinDeliveryDataAttributesInit = (
        tag: string,
        tag2: string,
        tag3: string,
        dataItemReadings: IIDataItemReadings,
        contributingEvent: IContributingEvent
    ) => {
        const readings = dataItemReadings.readingsCombo;
        const modes = dataItemReadings.modes;
        const submodes = dataItemReadings.submodes;
        const podStatuses = dataItemReadings.podStatuses;
        const payload: IChartPayloadData = {
            id: ConstantsHelper.DataSeriesInsulinDelivery,
            data: [],
        };

        readings?.forEach((p) => {
            const m = moment(p.time).utcOffset(0);

            payload.data.push({
                m,
                x: m.utcOffset(0).format(),
            });
        });

        const allYs = payload.data?.map((d) => d.y) ?? [];
        const modeSeries: IChartPayloadData = {
            ...payload,
            data: payload.data.map((e) => ({ ...e, y: null })),
        };
        const insulinDeliveryDataAttributes: IChartPayload = {
            crossOvers: ChartHelper.getCrossOvers(payload.data),
            data: [modeSeries],
            tag,
            tag2,
            tag3,
            windowBeg: contributingEvent.windowBeg,
            windowEnd: contributingEvent.windowEnd,
            xMax: payload.data.length ? payload.data[payload.data.length - 1].x : null,
            xMin: payload.data.length ? payload.data[0].x : null,
            yMax: UtilityHelper.GetMaxVal(allYs),
            yMin: UtilityHelper.GetMinVal(allYs),
        };

        const trackerMarkToh: IBulletPoint<DataItemModesPodStatuses.StatusEnum | string>[] =
            insulinDeliveryDataAttributes?.crossOvers?.hourly?.map((e) => ({
                end: DateTimeHelper.GetDurationInSeconds(insulinDeliveryDataAttributes.windowBeg, e),
                type: ConstantsHelper.DataSubTypeTopOfHour,
                payload: DateTimeHelper.FormatTimeForChart(e, true),
            }));
        const trackerMark: IBulletPoint<DataItemModesPodStatuses.StatusEnum | string>[] = trackerMarkToh?.length
            ? trackerMarkToh
            : [];

        return {
            modes,
            submodes,
            podStatuses,
            modeSeries,
            insulinDeliveryDataAttributes,
            trackerMark,
        };
    };

    private static getInsulinDeliveryDataAttributesAddTrackerRange = (
        modes: Mode[],
        trackerRange: IBulletPoint<Mode.ValueEnum>[],
        insulinDeliveryDataAttributes: IChartPayload,
        endLast: number
    ) => {
        modes?.forEach((modeEntry, idx) => {
            const begTs = modeEntry.startDate;
            const endTs = modeEntry.endDate;
            const endOld = idx > 0 ? trackerRange[trackerRange.length - 1].end : 0;
            const begNew = DateTimeHelper.GetDurationInSeconds(insulinDeliveryDataAttributes.windowBeg, begTs);
            const endNew = DateTimeHelper.GetDurationInSeconds(insulinDeliveryDataAttributes.windowBeg, endTs);
            let payload = '';

            switch (modeEntry.value) {
                case Mode.ValueEnum.Automated:
                    payload = ConstantsHelper.Chart.colorAutomated;
                    break;
                case Mode.ValueEnum.Manual:
                    payload = ConstantsHelper.Chart.colorManual;
                    break;
                case Mode.ValueEnum.Limited:
                    payload = ConstantsHelper.Chart.colorLimited;
                    break;
                case Mode.ValueEnum.Activity:
                    payload = ConstantsHelper.Chart.colorActivity;
                    break;
            }

            if (endOld < begNew) {
                trackerRange.push({
                    beg: endOld,
                    end: begNew,
                    type: null,
                    payload: ConstantsHelper.Chart.colorTransparent,
                });
            }

            trackerRange.push({
                beg: begNew,
                end: endNew,
                type: modeEntry.value,
                payload,
                begTs,
                endTs,
            });
        });

        if (trackerRange.length === 0 || trackerRange[trackerRange.length - 1].end !== endLast) {
            trackerRange.push({
                beg: trackerRange.length === 0 ? 0 : trackerRange[trackerRange.length - 1].end,
                end: endLast,
                type: null,
                payload: ConstantsHelper.Chart.colorTransparent,
            });
        }
    };

    private static getInsulinDeliveryDataAttributesAddTrackerMeasure = (
        submodes: Submode[],
        trackerMeasure: IBulletPoint<Submode.ValueEnum>[],
        insulinDeliveryDataAttributes: IChartPayload,
        endLast: number
    ) => {
        submodes?.forEach((submodeEntry, idx) => {
            const begTs = submodeEntry.startDate;
            const endTs = submodeEntry.endDate;
            const endOld = idx > 0 ? trackerMeasure[trackerMeasure.length - 1].end : 0;
            const begNew = DateTimeHelper.GetDurationInSeconds(insulinDeliveryDataAttributes.windowBeg, begTs);
            const endNew = DateTimeHelper.GetDurationInSeconds(insulinDeliveryDataAttributes.windowBeg, endTs);
            let payload = '';

            switch (submodeEntry.value) {
                case Submode.ValueEnum.InsulinMax:
                    payload = ConstantsHelper.Chart.colorInsulinMax;
                    break;
                case Submode.ValueEnum.InsulinPaused:
                    payload = ConstantsHelper.Chart.colorInsulinPaused;
                    break;
                case Submode.ValueEnum.BasalMode:
                    payload = ConstantsHelper.Chart.colorBasalMode;
                    break;
            }

            if (endOld < begNew) {
                trackerMeasure.push({
                    beg: endOld,
                    end: begNew,
                    type: null,
                    payload: ConstantsHelper.Chart.colorTransparent,
                });
            }

            trackerMeasure.push({
                beg: begNew,
                end: endNew,
                type: submodeEntry.value,
                payload,
                begTs,
                endTs,
            });
        });

        if (trackerMeasure.length === 0 || trackerMeasure[trackerMeasure.length - 1].end !== endLast) {
            trackerMeasure.push({
                beg: trackerMeasure.length === 0 ? 0 : trackerMeasure[trackerMeasure.length - 1].end,
                end: endLast,
                type: null,
                payload: ConstantsHelper.Chart.colorTransparent,
            });
        }
    };

    private static getInsulinDeliveryDataAttributesAddTrackerMark = (
        podStatuses: DataItemModesPodStatuses[],
        trackerMark: IBulletPoint<DataItemModesPodStatuses.StatusEnum | string>[],
        insulinDeliveryDataAttributes: IChartPayload
    ) => {
        podStatuses?.forEach((podStatusesEntry) => {
            const endTs = podStatusesEntry.timestamp;
            const end = DateTimeHelper.GetDurationInSeconds(insulinDeliveryDataAttributes.windowBeg, endTs);
            let payload = '';

            switch (podStatusesEntry.status) {
                case DataItemModesPodStatuses.StatusEnum.Activated:
                    payload = podActivated;
                    break;
                case DataItemModesPodStatuses.StatusEnum.Activating:
                    payload = podActivating;
                    break;
                case DataItemModesPodStatuses.StatusEnum.Deactivated:
                    payload = podDeactivated;
                    break;
            }

            trackerMark.push({
                end,
                type: podStatusesEntry.status,
                endTs,
                payload,
            });
        });
    };

    public static GetInsulinDeliveryDataAttributes = (
        tag: string,
        tag2: string,
        tag3: string,
        dataItemReadings: IIDataItemReadings,
        contributingEvent: IContributingEvent
    ): IChartPayload => {
        const { modes, submodes, podStatuses, modeSeries, insulinDeliveryDataAttributes, trackerMark } =
            ChartHelper.getInsulinDeliveryDataAttributesInit(tag, tag2, tag3, dataItemReadings, contributingEvent);
        const trackerMeasure: IBulletPoint<Submode.ValueEnum>[] = [];
        const trackerRange: IBulletPoint<Mode.ValueEnum>[] = [];
        const modeSeriesLen = modeSeries?.data?.length;

        if (modeSeriesLen > 0) {
            const endLast = DateTimeHelper.GetDurationInSeconds(
                insulinDeliveryDataAttributes.windowBeg,
                modeSeries.data[modeSeriesLen - 1].x
            );

            ChartHelper.getInsulinDeliveryDataAttributesAddTrackerRange(
                modes,
                trackerRange,
                insulinDeliveryDataAttributes,
                endLast
            );
            ChartHelper.getInsulinDeliveryDataAttributesAddTrackerMeasure(
                submodes,
                trackerMeasure,
                insulinDeliveryDataAttributes,
                endLast
            );
            ChartHelper.getInsulinDeliveryDataAttributesAddTrackerMark(
                podStatuses,
                trackerMark,
                insulinDeliveryDataAttributes
            );
        }

        return {
            ...insulinDeliveryDataAttributes,
            trackerMark: [...trackerMark].sort((m1, m2) => (m1.end < m2.end ? -1 : 1)),
            trackerMeasure,
            trackerRange,
        };
    };

    private static getInsulinLegendEntries = (
        showtestTips: boolean,
        header: string,
        entries: ILegendEntry[]
    ): ILegendSection[] => {
        const sections: ILegendSection[] = [];
        const body = entries
            .filter((e) => e.test || showtestTips)
            .map(
                (e) =>
                    ({
                        label: e.label,
                        color: e.color,
                        img: e.img,
                        forceShow: e.test ? false : showtestTips,
                    } as ILegendEntry)
            );

        if (body.length) {
            sections.push({
                header,
                body,
                forceShow: showtestTips && body.filter((b) => b.forceShow).length === body.length,
            });
        }

        return sections;
    };

    public static GetInsulinLegendEntriesInScope = ({
        haveActivity,
        haveAutomated,
        haveLimited,
        haveManual,
        haveInsulinMax,
        haveInsulinPaused,
        haveTempBasal,
        havePodActivated,
        havePodActivating,
        havePodDeactivated,
    }: {
        haveActivity: boolean;
        haveAutomated: boolean;
        haveLimited: boolean;
        haveManual: boolean;
        haveInsulinMax: boolean;
        haveInsulinPaused: boolean;
        haveTempBasal: boolean;
        havePodActivated: boolean;
        havePodActivating: boolean;
        havePodDeactivated: boolean;
    }): ILegendEntry[] => {
        return [
            { test: haveManual, label: 'manualMode', color: ConstantsHelper.Chart.colorManual },
            { test: haveAutomated, label: 'automatedMode', color: ConstantsHelper.Chart.colorAutomated },
            { test: haveLimited, label: 'automatedModeLimited', color: ConstantsHelper.Chart.colorLimited },
            { test: haveActivity, label: 'activity', color: ConstantsHelper.Chart.colorActivity },
            { test: haveTempBasal, label: 'tempBasal', color: ConstantsHelper.Chart.colorBasalMode },
            { test: haveInsulinPaused, label: 'insulinPaused', color: ConstantsHelper.Chart.colorInsulinPaused },
            { test: haveInsulinMax, label: 'insulinMax', color: ConstantsHelper.Chart.colorInsulinMax },
            { test: havePodDeactivated, label: 'podDeactivated', img: podDeactivated },
            { test: havePodActivated, label: 'podActivated', img: podActivated },
            { test: havePodActivating, label: 'podActivating', img: podActivating },
        ];
    };

    public static GetInsulinLegendEntries = (
        insulinDeliveryDataAttributes: IChartPayload,
        showtestTips: boolean
    ): ILegendSection[] => {
        let haveActivity = false;
        let haveAutomated = false;
        let haveLimited = false;
        let haveManual = false;
        let haveInsulinMax = false;
        let haveInsulinPaused = false;
        let haveTempBasal = false;
        let havePodActivated = false;
        let havePodActivating = false;
        let havePodDeactivated = false;

        insulinDeliveryDataAttributes.trackerRange.forEach((e) => {
            if (!haveActivity && e.type === Mode.ValueEnum.Activity) {
                haveActivity = true;
            }
            if (!haveAutomated && e.type === Mode.ValueEnum.Automated) {
                haveAutomated = true;
            }
            if (!haveLimited && e.type === Mode.ValueEnum.Limited) {
                haveLimited = true;
            }
            if (!haveManual && e.type === Mode.ValueEnum.Manual) {
                haveManual = true;
            }
        });
        insulinDeliveryDataAttributes.trackerMeasure.forEach((e) => {
            if (!haveInsulinMax && e.type === Submode.ValueEnum.InsulinMax) {
                haveInsulinMax = true;
            }
            if (!haveInsulinPaused && e.type === Submode.ValueEnum.InsulinPaused) {
                haveInsulinPaused = true;
            }
            if (!haveTempBasal && e.type === Submode.ValueEnum.BasalMode) {
                haveTempBasal = true;
            }
        });
        insulinDeliveryDataAttributes.trackerMark.forEach((e) => {
            if (!havePodActivated && e.type === DataItemModesPodStatuses.StatusEnum.Activated) {
                havePodActivated = true;
            }
            if (!havePodActivating && e.type === DataItemModesPodStatuses.StatusEnum.Activating) {
                havePodActivating = true;
            }
            if (!havePodDeactivated && e.type === DataItemModesPodStatuses.StatusEnum.Deactivated) {
                havePodDeactivated = true;
            }
        });

        const entries: ILegendEntry[] = ChartHelper.GetInsulinLegendEntriesInScope({
            haveActivity,
            haveAutomated,
            haveLimited,
            haveManual,
            haveInsulinMax,
            haveInsulinPaused,
            haveTempBasal,
            havePodActivated,
            havePodActivating,
            havePodDeactivated,
        });

        return ChartHelper.getInsulinLegendEntries(showtestTips, 'header', entries);
    };

    public static GetInsulinTooltipEntries = (
        insulinDeliveryDataAttributes: IChartPayload,
        beg: number,
        end: number
    ): ILegendEntry[] => {
        const trackerRangeMatching = insulinDeliveryDataAttributes.trackerRange.filter(
            (e) => e.type && UtilityHelper.CheckForRangeOverlap(e.beg, e.end - 1, beg, end - 1)
        );
        const trackerMeasureMatching = insulinDeliveryDataAttributes.trackerMeasure.filter(
            (e) => e.type && UtilityHelper.CheckForRangeOverlap(e.beg, e.end - 1, beg, end - 1)
        );
        const trackerMarkMatching = insulinDeliveryDataAttributes.trackerMark.filter(
            (e) => e.type && e.type !== ConstantsHelper.DataSubTypeTopOfHour && e.end >= end - 44 && e.end <= end + 12
        );
        const haveActivity = !!trackerRangeMatching.find((e) => e.type === Mode.ValueEnum.Activity);
        const haveAutomated = !!trackerRangeMatching.find((e) => e.type === Mode.ValueEnum.Automated);
        const haveLimited = !!trackerRangeMatching.find((e) => e.type === Mode.ValueEnum.Limited);
        const haveManual = !!trackerRangeMatching.find((e) => e.type === Mode.ValueEnum.Manual);
        const haveInsulinMax = !!trackerMeasureMatching.find((e) => e.type === Submode.ValueEnum.InsulinMax);
        const haveInsulinPaused = !!trackerMeasureMatching.find((e) => e.type === Submode.ValueEnum.InsulinPaused);
        const haveTempBasal = !!trackerMeasureMatching.find((e) => e.type === Submode.ValueEnum.BasalMode);
        const havePodActivated = !!trackerMarkMatching.find(
            (e) => e.type === DataItemModesPodStatuses.StatusEnum.Activated
        );
        const havePodActivating = !!trackerMarkMatching.find(
            (e) => e.type === DataItemModesPodStatuses.StatusEnum.Activating
        );
        const havePodDeactivated = !!trackerMarkMatching.find(
            (e) => e.type === DataItemModesPodStatuses.StatusEnum.Deactivated
        );

        return ChartHelper.GetInsulinLegendEntriesInScope({
            haveActivity,
            haveAutomated,
            haveLimited,
            haveManual,
            haveInsulinMax,
            haveInsulinPaused,
            haveTempBasal,
            havePodActivated,
            havePodActivating,
            havePodDeactivated,
        });
    };
}
