import { Box, Slider, Switch, Tab, Tabs, Typography, Tooltip } from '@mui/material';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import React, { useMemo, useState } from 'react';

import styleGeneral from '../../styles/general.module.scss';
import { IBrowserInfo, ICallback, IClickCoordinates, IControl, IHome, IPatient, IReadingsInfo } from '../../types';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import StringHelper from '../../helpers/StringHelper';
import UtilityHelper from '../../helpers/UtilityHelper';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

const tabProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: {
        fontWeight: 800,
        fontSize: '10px',
        fontVariant: 'normal',
    },
});

const renderButton = ({
    tooltip,
    onClickCb,
    tag,
    testId,
    isOn,
    payload,
    keys,
    dblWidth,
}: {
    tooltip?: string;
    onClickCb: ICallback<void>;
    tag: string;
    testId: string;
    isOn: boolean;
    payload?: any;
    keys?: string[];
    dblWidth?: boolean;
}) => (
    <Tooltip title={tooltip ?? `Set ${tag} ${isOn ? 'Off' : 'On'}`}>
        <span
            className={clsx(styleGeneral.buttonDefault, dblWidth ? styleGeneral.buttonWide : null)}
            onClick={() => onClickCb({ payload, keys })}
        >
            {tag}
            <Switch className={styleGeneral.switch} size="small" checked={isOn} data-testid={`controlPanel${testId}`} />
        </span>
    </Tooltip>
);
const renderSlider = ({
    tooltip,
    onChangeCb,
    tag,
    testId,
    unitName,
    min,
    max,
    sliderValue,
}: {
    tooltip: string;
    onChangeCb: ICallback<void>;
    tag: string;
    testId: string;
    unitName: string;
    min: number;
    max: number;
    sliderValue: number;
}) => (
    <Tooltip title={tooltip}>
        <span className={clsx(styleGeneral.buttonDefault, styleGeneral.buttonWide)}>
            <div className={styleGeneral.sliderCaption}>
                {tag} ({StringHelper.Pluralize(sliderValue, unitName)})
            </div>
            <Slider
                data-testid={`controlPanel${testId}`}
                value={sliderValue}
                valueLabelDisplay="auto"
                min={min}
                max={max}
                onChange={(event: any, value: number) => onChangeCb(value)}
                className={styleGeneral.slider}
            />
        </span>
    </Tooltip>
);
function ControlPanel({
    home,
    setControlPanelSwitchCb,
    setProfileCb,
    updateProfileCb,
}: {
    home: IHome;
    setControlPanelSwitchCb: ICallback<void>;
    setProfileCb: ICallback<void>;
    updateProfileCb: ICallback<void>;
}) {
    const [value, setValue] = useState(0);
    const [sliderValue, setSliderValue] = useState(UtilityHelper.ReportDaysAvailableGet(home.patient));
    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);
    const control = home.control ?? ({} as IControl);
    const browserInfo = control.browserInfo ?? ({} as IBrowserInfo);
    const expanded = control.panelExpanded ?? true;
    const panelPos = control.panelPos ?? ({} as IClickCoordinates);
    const readingsInfo = control.readingsInfo ?? ({} as IReadingsInfo);
    const renderBtnWelcomeTitle = `Set "welcomeSeen" flag = ${home.patient?.welcomeSeen ? 'False' : 'True'}`;
    const renderBtnDisclaimerTitle = `Set "disclaimerSeen" flag = ${home.patient?.disclaimerSeen ? 'False' : 'True'}`;
    const btnTitle = `Display date(s) displayed around mindnight ${
        readingsInfo.flipDateBy90 ? 'horizontally' : 'vertically'
    }`;
    const setControlPanelSwitchCbLocal = ({ payload, keys }: { payload: Partial<IControl>; keys: string[] }) =>
        setControlPanelSwitchCb({ control1: payload, keys });

    const updateProfileCbLocal = ({ payload }: { payload: any }) => {
        updateProfileCb({ control2: payload });
    };

    const setExpanded = (panelExpanded: boolean) =>
        setControlPanelSwitchCbLocal({
            payload: {
                panelExpanded,
            } as IControl,
            keys: [ConstantsHelper.IntersessionKeys.controlPanelExpanded],
        });
    const enrollmentPayloadGet = useMemo(
        () =>
            (enrollmentDaysValue: number): Partial<IPatient> => {
                setSliderValue(enrollmentDaysValue);

                return {
                    reportDaysAvailableString: UtilityHelper.CryptoEncrypt(`${enrollmentDaysValue}`),
                };
            },
        []
    );

    return (
        <Draggable
            handle="#controlsHandle"
            onStop={(e: any) =>
                setControlPanelSwitchCbLocal({
                    payload: {
                        panelPos: {
                            left: e?.x - e?.offsetX,
                            top: e?.y - e?.offsetY,
                        },
                    } as IControl,
                    keys: [
                        ConstantsHelper.IntersessionKeys.controlPanelLeft,
                        ConstantsHelper.IntersessionKeys.controlPanelTop,
                    ],
                })
            }
            position={{ x: panelPos.left, y: panelPos.top }}
        >
            <div className={styleGeneral.windowTestFrame} id={ConstantsHelper.ControlPanelId}>
                <div className={styleGeneral.windowTest}>
                    <div
                        data-testid={'controlPanelTitle'}
                        id="controlsHandle"
                        className={styleGeneral.testHeader}
                        onDoubleClick={() => setExpanded(!expanded)}
                    >
                        Test Controls
                    </div>
                    <Tooltip title={`${expanded ? 'Collapse' : 'Expand'} Control Tabs`}>
                        <div
                            data-testid={'controlPanelToggle'}
                            className={styleGeneral.testToggle}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? '\u25B2' : '\u25BC'}
                        </div>
                    </Tooltip>
                    {expanded ? (
                        <div className={styleGeneral.testBody}>
                            <div>
                                <Box
                                    p={0}
                                    sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                    }}
                                >
                                    <Tabs
                                        orientation="horizontal"
                                        variant="standard"
                                        value={value}
                                        onChange={handleChange}
                                        className={styleGeneral.tabs}
                                    >
                                        <Tooltip title="Controls spanning the entire WebApp">
                                            <Tab
                                                data-testid={'controlPanelTab0'}
                                                className={styleGeneral.tabHeader}
                                                label="WebApp"
                                                {...tabProps(0)}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Controls for Dashboard">
                                            <Tab
                                                data-testid={'controlPanelTab1'}
                                                className={styleGeneral.tabHeader}
                                                label="Dashboard"
                                                {...tabProps(1)}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Controls for Event Details">
                                            <Tab
                                                data-testid={'controlPanelTab2'}
                                                className={styleGeneral.tabHeader}
                                                label="Event"
                                                {...tabProps(2)}
                                            />
                                        </Tooltip>
                                    </Tabs>
                                </Box>
                                <div className={styleGeneral.tabPanel}>
                                    <TabPanel value={value} index={0}>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'See Additional Tips/Information',
                                                testId: 'Tips',
                                                isOn: control.testTips,
                                                payload: { testTips: !control.testTips },
                                                keys: [ConstantsHelper.IntersessionKeys.testTips],
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Generate Test Data',
                                                testId: 'GenData',
                                                isOn: control.testGenData,
                                                payload: { testGenData: !control.testGenData },
                                                keys: [ConstantsHelper.IntersessionKeys.testGenData],
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Simulate Pause IT ON',
                                                testId: 'PauseIT',
                                                isOn: control.testPauseItEnabledOverride,
                                                payload: {
                                                    testPauseItEnabledOverride: !control.testPauseItEnabledOverride,
                                                },
                                                keys: [ConstantsHelper.IntersessionKeys.testPauseItEnabledOverride],
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                tooltip: renderBtnWelcomeTitle,
                                                onClickCb: updateProfileCbLocal,
                                                tag: 'Already Seen Welcome Modal',
                                                testId: 'SeenWelcome',
                                                isOn: home.patient?.welcomeSeen,
                                                payload: {
                                                    welcomeSeen: !home.patient?.welcomeSeen,
                                                },
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                tooltip: renderBtnDisclaimerTitle,
                                                onClickCb: updateProfileCbLocal,
                                                tag: 'Already Seen Disclaimer Modal',
                                                testId: 'SeenDisclaimer',
                                                isOn: home.patient?.disclaimerSeen,
                                                payload: {
                                                    disclaimerSeen: !home.patient?.disclaimerSeen,
                                                },
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Prod Mode',
                                                testId: 'Prod',
                                                isOn: control.isProd,
                                                payload: {
                                                    isProd: !control.isProd,
                                                },
                                            })}
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'OK Browser',
                                                testId: 'Browser',
                                                isOn: browserInfo.supported,
                                                payload: {
                                                    browserInfo: {
                                                        ...browserInfo,
                                                        supported: !browserInfo.supported,
                                                    },
                                                },
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Force Unenrolled (401)',
                                                testId: 'Unenrolled',
                                                isOn: control.testForceRevoked,
                                                payload: {
                                                    testForceRevoked: !control.testForceRevoked,
                                                },
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Force Enrollment Pending (404)',
                                                testId: 'Enrolled',
                                                isOn: control.testForcePending,
                                                payload: {
                                                    testForcePending: !control.testForcePending,
                                                },
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderSlider({
                                                tooltip: 'Adjust number of enrollment days',
                                                onChangeCb: (newSliderValue: number) => {
                                                    setProfileCb(enrollmentPayloadGet(newSliderValue));
                                                },
                                                tag: 'Enrollment Days',
                                                testId: 'EnrollmentDays',
                                                unitName: 'day',
                                                min: 0,
                                                max: 999,
                                                sliderValue,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Force PWD as NOT Onboarded',
                                                testId: 'IsOffboarded',
                                                isOn: control.testKeepOffboard,
                                                payload: {
                                                    testKeepOffboard: !control.testKeepOffboard,
                                                    testKeepOnboard: false,
                                                },
                                                keys: [
                                                    ConstantsHelper.IntersessionKeys.testKeepOffboard,
                                                    ConstantsHelper.IntersessionKeys.testKeepOnboard,
                                                ],
                                                dblWidth: true,
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Force PWD as Onboarded',
                                                testId: 'IsOnboarded',
                                                isOn: control.testKeepOnboard,
                                                payload: {
                                                    testKeepOffboard: false,
                                                    testKeepOnboard: !control.testKeepOnboard,
                                                },
                                                keys: [
                                                    ConstantsHelper.IntersessionKeys.testKeepOffboard,
                                                    ConstantsHelper.IntersessionKeys.testKeepOnboard,
                                                ],
                                                dblWidth: true,
                                            })}
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Future',
                                                testId: 'Future',
                                                isOn: control.testFuture,
                                                payload: { testFuture: !control.testFuture },
                                                keys: [ConstantsHelper.IntersessionKeys.testFuture],
                                            })}
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Calendar',
                                                testId: 'Calendar',
                                                isOn: control.testCalendar,
                                                payload: { testCalendar: !control.testCalendar },
                                                keys: [ConstantsHelper.IntersessionKeys.testCalendar],
                                            })}
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Fill Gaps',
                                                testId: 'FillGaps',
                                                isOn: readingsInfo.fillGaps,
                                                payload: {
                                                    readingsInfo: {
                                                        fillGaps: !readingsInfo.fillGaps,
                                                    },
                                                },
                                            })}
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'EGV Table',
                                                testId: 'EgvTable',
                                                isOn: readingsInfo.showTable,
                                                payload: {
                                                    readingsInfo: {
                                                        showTable: !readingsInfo.showTable,
                                                    },
                                                },
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: '+EGV',
                                                testId: 'EGV',
                                                isOn: readingsInfo.showRecsEgv,
                                                payload: {
                                                    readingsInfo: {
                                                        showRecsEgv: !readingsInfo.showRecsEgv,
                                                    },
                                                },
                                            })}
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: '+Bolus',
                                                testId: 'Bolus',
                                                isOn: readingsInfo.showRecsBolus,
                                                payload: {
                                                    readingsInfo: {
                                                        showRecsBolus: !readingsInfo.showRecsBolus,
                                                    },
                                                },
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: '+Mode',
                                                testId: 'Mode',
                                                isOn: readingsInfo.showRecsMode,
                                                payload: {
                                                    readingsInfo: {
                                                        showRecsMode: !readingsInfo.showRecsMode,
                                                    },
                                                },
                                            })}
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: '+Submode',
                                                testId: 'Submode',
                                                isOn: readingsInfo.showRecsSubMode,
                                                payload: {
                                                    readingsInfo: {
                                                        showRecsSubMode: !readingsInfo.showRecsSubMode,
                                                    },
                                                },
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: '+Pod',
                                                testId: 'Pod',
                                                isOn: readingsInfo.showRecsPod,
                                                payload: {
                                                    readingsInfo: {
                                                        showRecsPod: !readingsInfo.showRecsPod,
                                                    },
                                                },
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Vert. Lines',
                                                testId: 'VertLines',
                                                isOn: readingsInfo.showBoundaries,
                                                payload: {
                                                    readingsInfo: {
                                                        showBoundaries: !readingsInfo.showBoundaries,
                                                    },
                                                },
                                            })}
                                            {renderButton({
                                                tooltip: btnTitle,
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Flip Date 90°',
                                                testId: 'FlipDate90',
                                                isOn: readingsInfo.flipDateBy90,
                                                payload: {
                                                    readingsInfo: {
                                                        flipDateBy90: !readingsInfo.flipDateBy90,
                                                    },
                                                },
                                            })}
                                        </div>
                                        <div className={styleGeneral.row}>
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Move Icons',
                                                testId: 'MoveIcons',
                                                isOn: readingsInfo.lowerBolus,
                                                payload: {
                                                    readingsInfo: {
                                                        lowerBolus: !readingsInfo.lowerBolus,
                                                    },
                                                },
                                            })}
                                            {renderButton({
                                                onClickCb: setControlPanelSwitchCbLocal,
                                                tag: 'Show Legend',
                                                testId: 'Legend',
                                                isOn: control.showLegend,
                                                payload: {
                                                    showLegend: !control.showLegend,
                                                },
                                                keys: [ConstantsHelper.IntersessionKeys.showLegend],
                                            })}
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styleGeneral.testBodyCollapsed}></div>
                    )}
                </div>
            </div>
        </Draggable>
    );
}

export default ControlPanel;
