import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BtnPrimary from '../components/common/btnPrimary';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import SystemHelper from '../helpers/SystemHelper';
import { IError, IState } from '../types';
import UiHelper from '../helpers/UiHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import { errorActionCreator, homeInitialState } from '../store/home';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';

function Error() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const control = home.control;
    const browserInfo = control.browserInfo;
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    useEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'Welcome()', 'Mounted');
        dispatch(
            errorActionCreator({
                key: ConstantsHelper.httpCallAndErrorKeys.general,
                error: {
                    level: ConstantsHelper.ErrorLevels.error,
                    message: `${translate('notFound.alt')}!`,
                    silent: true,
                    title: '404',
                } as IError,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment translate={translate}>
            <div
                className={UiHelper.GetClassNamesHeader(
                    control.isProd,
                    browserInfo.supported,
                    styleGeneral.body,
                    styleGeneral.bodyAlt1,
                    styleGeneral.bodyAlt2
                )}
            >
                <div className={styleGeneral.verticalSpacer}>
                    <div className={styleGuide.errorPage}>
                        <div className={styleGuide.title}>404 {translate('notFound.alt')}!</div>
                        <BtnPrimary
                            caption={translate('goToHomeScreen')}
                            cbClickFn={() => (window.location.href = '/')}
                            focus={true}
                        />
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default Error;
