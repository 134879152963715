import { Nav, Navbar } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import iconOminpodDiscover from '../../assets/images/icon-omnipod-discover.png';
import { IBrowserInfo, ICallback, IControl, IHome, IMenuEntry, ITranslator } from '../../types';
import MenuHelper from '../../helpers/MenuHelper';
import Identification from '../common/identificiation';
import UiHelper from '../../helpers/UiHelper';

const menuLabelToShow = (testMode: boolean, entry: IMenuEntry) => {
    let active = false;

    if (entry.key === MenuHelper.MenuEntryTestMode.key) {
        active = testMode;
    }

    return (
        <span className={clsx(styleGuide.H3BlackLeft, styleGeneral.menuItem, active && styleGeneral.menuItemOn)}>
            <div className={styleGeneral.iconFrame}>
                {entry?.icon && <img src={entry.icon} alt={entry?.label} className={styleGeneral.icon} />}
            </div>
            <div className={styleGeneral.labelFrame}>{entry?.label}</div>
        </span>
    );
};
const renderBannerNotIsProd = (translate: ITranslator, notIsProd: boolean) =>
    notIsProd && <div className={styleGeneral.nonProdUse}>{translate('nonProdUse')}</div>;
const renderBannerIsUnsupportedBrowser = (
    translate: ITranslator,
    notIsProd: boolean,
    isUnsupportedBrowser: boolean
) => {
    if (isUnsupportedBrowser) {
        const unsupportedBrowserClass = notIsProd
            ? styleGeneral.unsupportedBrowserPositionSecond
            : styleGeneral.unsupportedBrowserPositionFirst;

        return <div className={unsupportedBrowserClass}>{translate('unsupportedBrowser')}</div>;
    }

    return null;
};
const getNavBoxClass = (notIsProd: boolean, isUnsupportedBrowser: boolean) => {
    let classToUse = styleGeneral.navbarBox;

    if (notIsProd && isUnsupportedBrowser) {
        classToUse = styleGeneral.navbarBoxWithBannerDouble;
    } else if (notIsProd || isUnsupportedBrowser) {
        classToUse = styleGeneral.navbarBoxWithBannerSingle;
    }

    return classToUse;
};

function Menu({
    home,
    menuEntries,
    isMenuOpen,
    tokenLifeSecLeft,
    menuOpenCloseCb,
    translate,
}: {
    home: IHome;
    menuEntries: IMenuEntry[];
    isMenuOpen: boolean;
    tokenLifeSecLeft: number;
    menuOpenCloseCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const browserInfo = control?.browserInfo ?? ({} as IBrowserInfo);
    const testSubCaption = `${home.endpointPwdUrlPrefix}//no env-`.split('/')[2].split('-')[0];
    const testCaption =
        control.testTips && control.testMode
            ? `User "${home.authentication?.oktaData?.userName}" on "${testSubCaption}" token expires in ${tokenLifeSecLeft} sec`
            : '';
    const notIsProd = !control.isProd;
    const isUnsupportedBrowser = !browserInfo.supported;

    return (
        <div className={styleGeneral.main}>
            {renderBannerNotIsProd(translate, notIsProd)}
            {renderBannerIsUnsupportedBrowser(translate, notIsProd, isUnsupportedBrowser)}
            <Navbar
                className={clsx(getNavBoxClass(notIsProd, isUnsupportedBrowser), 'navbar', 'navbar-light', 'bg-light')}
                collapseOnSelect
                expand={false}
                bg="light"
                variant="light"
            >
                <Tooltip title={translate('goToHomeScreen')}>
                    <Navbar.Brand
                        href="/"
                        className={clsx(styleGeneral.navbarBrand, styleGuide.banner)}
                        onClick={() => menuOpenCloseCb({ isMenuOpen: false, menuEntry: MenuHelper.MenuEntryHome })}
                    >
                        <img className={styleGeneral.navbarLogo} src={iconOminpodDiscover} alt="Omnipod Discover™" />
                        {control.testTips && control.testMode && (
                            <Tooltip title="Client Config Information">
                                <span className={clsx(styleGeneral.testModeOn, styleGeneral.qaAdvisory)}>
                                    {testCaption}
                                </span>
                            </Tooltip>
                        )}
                    </Navbar.Brand>
                </Tooltip>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    className={clsx(styleGeneral.navbarHamburger, styleGeneral.layerTopmost)}
                    onClick={() => menuOpenCloseCb({ isMenuOpen: !isMenuOpen })}
                >
                    <div className={styleGeneral.menu}>
                        <div className={clsx(styleGeneral.bar1, isMenuOpen && styleGeneral.bar1Change)}></div>
                        <div className={clsx(styleGeneral.bar2, isMenuOpen && styleGeneral.bar2Change)}></div>
                        <div className={clsx(styleGeneral.bar3, isMenuOpen && styleGeneral.bar3Change)}></div>
                    </div>
                </Navbar.Toggle>
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className={clsx(
                        styleGeneral.layerTopmost,
                        styleGeneral.scrollable,
                        UiHelper.GetClassNamesHeader(
                            control.isProd,
                            browserInfo.supported,
                            styleGeneral.menuFrame,
                            styleGeneral.menuFrameAlt1,
                            styleGeneral.menuFrameAlt2
                        )
                    )}
                >
                    <Nav className="mr-auto" data-testid="main-menu">
                        {menuEntries
                            ?.filter((menuEntry) => menuEntry.label?.length > 0)
                            ?.map((menuEntry, idx: number) => (
                                <span key={`navSpan${idx}`}>
                                    {menuEntry?.preSeparate && idx > 0 && (
                                        <span key={`navSpanT${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                    )}
                                    <Nav.Link
                                        data-testid={menuEntry?.label?.replace(' ', '_')}
                                        key={`navRow${idx}`}
                                        eventKey={`navLink${idx}`}
                                        href={menuEntry.path}
                                        onClick={() => menuOpenCloseCb({ isMenuOpen: false, menuEntry: menuEntry })}
                                    >
                                        {menuLabelToShow(control.testMode, menuEntry)}
                                    </Nav.Link>
                                    {menuEntry?.postSeparate && (
                                        <span key={`navSpanB${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                    )}
                                </span>
                            ))}
                    </Nav>
                    {control.testMode && (
                        <div className={styleGeneral.navbarId}>
                            {<Identification home={home} translate={translate} />}
                        </div>
                    )}
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu;
