import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import ContentWrapperConfirmSmsNumber from './ContentWrapperConfirmSmsNumber';

import UiHelper from '../../../helpers/UiHelper';
import SystemHelper from '../../../helpers/SystemHelper';
import { ICallback, IHome, ITranslator } from '../../../types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import CheckSingle from '../checkSingle';
import SmsNumberInput from '../SmsNumberInput';

const PHONE_CODE_PREFIX = '+1';

function ContentWrapperSmsNumber({
    home,
    verifyCb,
    confirmedCb,
    translate,
}: {
    home: IHome;
    verifyCb: ICallback<void>;
    confirmedCb: ICallback<void>;
    translate: ITranslator;
}) {
    const patientSmsNumber = home.patient.smsNumber;
    const smsNumberProvided = typeof patientSmsNumber === 'string';
    const smsNumberWithoutCode = smsNumberProvided ? patientSmsNumber.substring(2) : '';

    const [smsNumber, setSmsNumber] = useState(smsNumberWithoutCode);
    const [isVerifyClicked, setIsVerifyClicked] = useState(false);
    const [smsAgree, setSmsAgree] = useState(smsNumberWithoutCode !== '');

    const isSmsNumberValid = () => {
        const validPattern = /^\d{10}$/;
        return validPattern.test(smsNumber);
    };

    const isFormDataValid = smsAgree && smsNumber && isSmsNumberValid();

    const handleSmsNumberChange = (newSmsNumber: string) => {
        const validPattern = /^\d{0,10}$/;

        if (validPattern.test(newSmsNumber)) {
            setSmsNumber(newSmsNumber);
        }
    };

    const handleVerifySmsNumberClick = () => {
        if (isFormDataValid) {
            verifyCb(PHONE_CODE_PREFIX + smsNumber);
            setIsVerifyClicked(true);
        }
    };

    const handleConfirmSmsNumberResendCb = () => {
        verifyCb(PHONE_CODE_PREFIX + smsNumber);
    };

    const handleConfirmSmsNumberCloseCb = () => {
        setIsVerifyClicked(false);
    };

    const handleSmsAgreementCheckClick = (e: any) => {
        setSmsAgree(e?.checked);
    };

    const handleSmsAgreementCaptionCb = () => {
        return (
            <>
                {translate('verifySmsNumber.check')}
                <a
                    className={clsx(styleGeneral.link, styleGuide.underline)}
                    href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_SMS')}
                    target="__SMS__"
                >
                    {translate('verifySmsNumber.smsTermsAndConditions')}
                </a>
                {translate('verifySmsNumber.check2')}
            </>
        );
    };

    if (isVerifyClicked) {
        return (
            <ContentWrapperConfirmSmsNumber
                smsNumber={smsNumber}
                home={home}
                resendCb={handleConfirmSmsNumberResendCb}
                closeCb={handleConfirmSmsNumberCloseCb}
                confirmedCb={confirmedCb}
                translate={translate}
            />
        );
    }

    return (
        <div
            className={UiHelper.GetClassNamesHeader(
                home.control?.isProd,
                home.control?.browserInfo?.supported,
                styleGeneral.body,
                styleGeneral.bodyAlt1,
                styleGeneral.bodyAlt2,
                styleGeneral.whiteBackground
            )}
        >
            <div className={clsx(styleGuide.verifySmsNumber)}>
                <div className={styleGuide.title}>{translate('verifySmsNumber.header')}</div>
                <div className={styleGuide.paragraph}>
                    {translate('verifySmsNumber.subtitle', {
                        appName: translate('constants.appName'),
                    })}
                </div>
                <div className={styleGuide.paragraph}>{translate('verifySmsNumber.instructions')}</div>
                <SmsNumberInput
                    value={smsNumber}
                    label={translate('verifySmsNumber.phoneNumber')}
                    onChange={handleSmsNumberChange}
                />
                <div className={styleGuide.checkBoxContainer}>
                    <CheckSingle
                        data-testid="verify-sms-number__sms-agreement-check"
                        id="verify-sms-number__sms-agreement-check"
                        checked={smsAgree}
                        cbClickFn={handleSmsAgreementCheckClick}
                        captionCb={handleSmsAgreementCaptionCb}
                    />
                </div>
                <div className={styleGuide.buttons}>
                    <Link
                        data-testid="verify-sms-number__verify-button"
                        className={clsx(styleGuide.button, {
                            [styleGuide.primary]: isFormDataValid,
                            [styleGuide.disabled]: !isFormDataValid,
                        })}
                        to="#"
                        onClick={handleVerifySmsNumberClick}
                    >
                        {translate('verifySmsNumber.verify')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperSmsNumber;
