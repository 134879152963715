import React from 'react';

import ConstantsHelper from 'src/helpers/ConstantsHelper';
import { ITranslator } from 'src/types';

import UserNotAssignedToApplicationError from './UserNotAssignedToApplicationError';

function LoginError({ error, translate }: { error: Error; translate: ITranslator }) {
    if (error.message === ConstantsHelper.oktaLoginCallbackErrors.userNotAssignedToApplication) {
        return <UserNotAssignedToApplicationError translate={translate} />;
    }

    return (
        <div data-testid="login-error">
            {error.name && error.message
                ? `${error.name}: ${error.message}`
                : `${translate('loginError.error')}: ${error}`}
        </div>
    );
}

export default LoginError;
