import React, { useState } from 'react';
import clsx from 'clsx';

import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import logoWarning from '../../../assets/images/warning-icon.png';
import { IBrowserInfo, ICallback, IControl, IHome, ITranslator } from '../../../types';
import CheckSingle from '../checkSingle';
import UiHelper from '../../../helpers/UiHelper';
import NavigationHelper from '../../../helpers/NavigationHelper';

const idBtnContinue = 'idBtnContinue';

function ContentWrapperDisclaimer({
    home,
    closeCb,
    translate,
}: {
    home: IHome;
    closeCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const browserInfo = control.browserInfo ?? ({} as IBrowserInfo);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const setCheck = (setterFn: any, valueToSet: boolean) => {
        setterFn(valueToSet);
        NavigationHelper.ScrollIntoView(idBtnContinue);
    };
    const cbAccept = () => {
        closeCb();

        if (window.scrollY > 0) {
            window.scrollTo(0, 0);
        }
    };
    const handleDiscalimerCaptionCb = (translateKey1: string, translateKey2: string) => (
        <span className={styleGuide.checkBoxLabel}>
            {translate(translateKey1, {
                nameAlt: translate(translateKey2),
            })}
        </span>
    );

    return (
        <>
            <div
                className={clsx(
                    styleGeneral.layerTopmostLess1,
                    UiHelper.GetClassNamesHeader(
                        control.isProd,
                        browserInfo.supported,
                        styleGuide.modalBackdropTranslucent,
                        styleGuide.modalBackdropTranslucentAlt1,
                        styleGuide.modalBackdropTranslucentAlt2
                    )
                )}
            />
            <div className={clsx(styleGeneral.layerTopmostLess1, styleGuide.disclaimer)}>
                <img src={logoWarning} alt={`${'disclaimer.warningIcon'}`} className={styleGuide.icon} />
                <div className={styleGuide.title}>{translate('disclaimer.warning')}</div>
                <div className={styleGuide.par}>
                    {translate('disclaimer.par1', {
                        name: translate(ConstantsHelper.NameTag),
                        nameAlt: translate(ConstantsHelper.NameTagAlt2),
                        hyperHypo: translate(ConstantsHelper.CaptionHyperHypo),
                    })}
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'disclaimerChk1'}
                            id="disclaimerChk1"
                            checked={check1}
                            cbClickFn={(e: any) => setCheck(setCheck1, e?.checked)}
                            captionCb={() => handleDiscalimerCaptionCb('disclaimer.chk1', ConstantsHelper.NameTagAlt2)}
                        />
                    </div>
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'disclaimerChk2'}
                            id="disclaimerChk2"
                            checked={check2}
                            cbClickFn={(e: any) => setCheck(setCheck2, e?.checked)}
                            captionCb={() => handleDiscalimerCaptionCb('disclaimer.chk2', ConstantsHelper.NameTag)}
                        />
                    </div>
                </div>
                <div className={styleGuide.parLast} id={idBtnContinue}>
                    <BtnPrimary
                        disabled={!(check1 && check2)}
                        caption={translate('disclaimer.continue')}
                        cbClickFn={() => cbAccept()}
                        focus={false}
                    />
                </div>
            </div>
        </>
    );
}

export default ContentWrapperDisclaimer;
